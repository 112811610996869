import { paramsActionTypes, setParamsAction } from '@actions/params.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map } from 'rxjs';
import { ParamsService } from 'src/app/services/params.service';

@Injectable()
export class ParamsEffects {
  loadParamsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(paramsActionTypes.LOAD_PARAMS),
      mergeMap(() => {
        return this.paramsService.getParams().pipe(
          map((params) => {
            return setParamsAction({ params });
          })
        );
      })
    );
  });

  constructor(
    private paramsService: ParamsService,
    private actions$: Actions
  ) {}
}
