import { Injectable } from '@angular/core';
import { ITimeDuration, TimeDuration } from '@models/timer.model';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import {
  energyCountdownStartSelector$,
  lastQuestionnaireAnsweredTimestamp$,
} from '../store/selectors/timer.selectors';
import { userEnergyPointsSelector } from '../store/selectors/user.selectors';
import {
  energyCooldownSelector$,
  energyPointsCapSelector$,
  timeBetweenQuestionnairesSelector$,
} from '../store/selectors/params.selector';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  $energyCooldown = this.store.select(energyCooldownSelector$);
  $energyPointsCap = this.store.select(energyPointsCapSelector$);

  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  addMinutes(date: Date, minutes: number) {
    date.setMinutes(date.getMinutes() + minutes);
    return date;
  }

  getTimeFromNow(date: Date): number {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    return diff;
  }

  allocateTimeUnits(timeDifference: number): ITimeDuration {
    const days = Math.floor(
      (timeDifference /
        (this.hoursInADay *
          this.milliSecondsInASecond *
          this.minutesInAnHour *
          this.SecondsInAMinute)) %
        this.hoursInADay
    );
    const hours = Math.floor(
      (timeDifference /
        (this.milliSecondsInASecond *
          this.minutesInAnHour *
          this.SecondsInAMinute)) %
        this.hoursInADay
    );
    const minutes = Math.floor(
      (timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour)) %
        this.SecondsInAMinute
    );
    const seconds = Math.floor(
      (timeDifference / this.milliSecondsInASecond) % this.SecondsInAMinute
    );

    return { days: days, hours: hours, minutes: minutes, seconds: seconds };
  }
  subtractHours(date: Date, hours: number) {
    date.setHours(date.getHours() - hours);
    return date;
  }

  formatTimeString(timeUnit: number): string {
    return `${timeUnit.toString().padStart(2, '0')}`;
  }

  constructor(private store: Store) {}
  calculatePendingTokens(
    to: Date,
    from: Date,
    cooldownPeriod: TimeDuration
  ): { award: number; newStart: Date } {
    let remainderMilliSeconds = 0;
    let newStart!: Date;
    let award = 0;
    if (from) {
      const timePassed = to.getTime() - from.getTime();

      award = Math.floor(timePassed / cooldownPeriod.getTime());
      remainderMilliSeconds = timePassed % cooldownPeriod.getTime();
      newStart = new Date(to.getTime() - remainderMilliSeconds);
    }
    return { award, newStart };
  }

  async handleUserLogin(): Promise<void> {
    const tokens = await firstValueFrom(
      this.store.select(userEnergyPointsSelector)
    );
    const tokensCap = await firstValueFrom(this.$energyPointsCap);
    if (tokens >= tokensCap) {
      return;
    }

    const now = new Date();
    const cooldownStart = await firstValueFrom(
      this.store.select(energyCountdownStartSelector$)
    );
    const cooldownDuration = new TimeDuration(
      await firstValueFrom(this.$energyCooldown)
    );
    if (cooldownStart) {
      this.calculatePendingTokens(now, cooldownStart, cooldownDuration);
    }
  }

  createCountdownString(remaining: ITimeDuration) {
    return `${this.formatTimeString(remaining?.hours)}:${this.formatTimeString(
      remaining?.minutes
    )}:${this.formatTimeString(remaining?.seconds)}`;
  }

  calculateRemainingTimeMilliseconds(
    to: Date,
    from: Date,
    period: TimeDuration
  ): number {
    return from.getTime() + period.getTime() - to.getTime();
  }

  checkIfQuestionnaireIsNotAvailable(
    lastAnswer: Date,
    timeBetween: TimeDuration
  ) {
    if (!lastAnswer) return true;
    const timePassed = this.getTimeFromNow(lastAnswer);
    const canGetQuestionnaire = !Boolean(
      Math.floor(timePassed / timeBetween.getTime())
    );

    return canGetQuestionnaire;
  }

  public async QuestionnaireIsNotAvailable() {
    const $timeBetweenQuestionnaires = this.store.select(
      timeBetweenQuestionnairesSelector$
    );

    const $lastQuestionnaireAnsweredAt = this.store.select(
      lastQuestionnaireAnsweredTimestamp$
    );
    const timeBetween = new TimeDuration(
      await firstValueFrom($timeBetweenQuestionnaires)
    );
    const lastAnswer =
      (await firstValueFrom($lastQuestionnaireAnsweredAt)) ||
      this.getDateTimeXHoursAgo(timeBetween.days * 24 + timeBetween.hours + 1);

    return this.checkIfQuestionnaireIsNotAvailable(lastAnswer, timeBetween);
  }

  private getDateTimeXHoursAgo(hours: number): Date {
    const currentDateTime = new Date();
    const hoursAgo = new Date(
      currentDateTime.getTime() - hours * 60 * 60 * 1000
    );
    return hoursAgo;
  }
}
