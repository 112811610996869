import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Observable, of, tap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RegistrationApiService {
  private API_URL = environment.API_URL;

  private url = `${this.API_URL}`;
  constructor(private http: HttpClient) {}

  checkIfValid(registrationCode: string) {
    return this.http.get(
      `${this.API_URL}/register/code/valid?registration_code=${registrationCode}`
    ) as Observable<any>;
  }

  garminRedirect(garminCode: string) {
    console.log('garmin redirect', garminCode);
  }
}
