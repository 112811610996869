import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMultiLanguage } from '@models/multiLang.model';
import { IOptionUI, IQuestionUI } from '@models/question.model';
import { INTRO_IDS } from 'src/app/services/intro/data/intro_ids.intro';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
  @Input() lang!: keyof IMultiLanguage;
  @Input() question!: IQuestionUI;
  @Output() emitQuestionResponse = new EventEmitter();
  value: number = 0;
  questionsIntroIds = INTRO_IDS.QUESTIONS.ids;
  questionEmitsResponse(event: string | number, questionId: string) {
    this.emitQuestionResponse.emit({ event, questionId });
  }

  translateOptions(lang: keyof IMultiLanguage, options: IOptionUI[]) {
    let extensibleOptions = [...options];
    return extensibleOptions.map((option) => {
      let extensibleOption = { ...option };
      extensibleOption.translatedLabel =
        option.label[lang] || option.label['EN'];
      return extensibleOption;
    });
  }

  ngOnInit(): void {
    this.value = Number(this.question.answer);
  }
}
