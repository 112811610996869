import { IProgressExchange, IQuestsProgress } from '@models/progress.model';
import { IQuest, ITemperedQuest } from '@models/quest.model';
import { createAction, props } from '@ngrx/store';

export enum progressActionTypes {
  UNLOCK_QUEST = '[QUEST PROGRESS] UNLOCK',
  QUEST_PLAYED = '[QUEST PROGRESS] QUEST PLAYED',
  UPDATE_QUESTS = '[QUESTS PROGRESS] UPDATE',
  INCREASE_TIMES_PLAYED = '[QUEST PROGRESS] INCREASE TIMES PLAYED',
  SET_QUEST_PROGRESS = '[QUEST PROGRESS] SET',
  CLEAR_QUESTS = '[QUEST PROGRESS] CLEAR',
  UPDATE_PROGRESS = '[PROGRESS] UPDATE',
  RETRIEVE_PROGRESS = '[PROGRESS] RETRIEVE',
  RETRIEVE_PROGRESS_SUCCESS = '[PROGRESS] RETRIEVE SUCCESS',
  RETRIEVE_PROGRESS_ERROR = '[PROGRESS] RETRIEVE ERROR',
  CLEAR_PROGRESS = '[PROGRESS] CLEAR',
}

// Adds the quest in the unlocked quests list of the project slice.

// dispatched: on "START QUEST" button pressed: increaseTimesPlayed of quest.page
// effect: ProgressEffects - questPlayedEffect$ increases times played and checks if next quest is unlocked
// succeeding action: setQuestsProgressAction - sets quests progress
export const playQuestAction = createAction(
  progressActionTypes.QUEST_PLAYED,
  props<{ questId: number }>()
);
export const clearQuestsAction = createAction(progressActionTypes.CLEAR_QUESTS);

// Has a IQuestsProgress prop because it can take two quest to update: the current and the succeeding quest
// dispatched: ProgressEffects - questPlayedEffect$
// reducer: in progressReducers

export const setQuestsProgressAction = createAction(
  progressActionTypes.SET_QUEST_PROGRESS,
  props<{ questsProgress: IQuestsProgress }>()
);

// // dispatched:
// export const increaseTimesPlayedAction = createAction(
//   progressActionTypes.INCREASE_TIMES_PLAYED,
//   props<{ questId: number }>()
// );

// // dispatched: -
// export const unlockQuestAction = createAction(
//   progressActionTypes.UNLOCK_QUEST,
//   props<{ questId: number }>()
// );

export const updateProgressAction = createAction(
  progressActionTypes.UPDATE_PROGRESS
);

export const retrieveProgressActionSuccess = createAction(
  progressActionTypes.RETRIEVE_PROGRESS_SUCCESS,
  props<{ progressData: IProgressExchange; replace: Boolean }>()
);
export const retrieveProgressActionError = createAction(
  progressActionTypes.RETRIEVE_PROGRESS_ERROR
);

export const retrieveProgressAction = createAction(
  progressActionTypes.RETRIEVE_PROGRESS
);

export const clearProgressAction = createAction(
  progressActionTypes.CLEAR_PROGRESS
);
