import { setActiveLanguageAction } from '@actions/settings.actions';
import { createReducer, on } from '@ngrx/store';
import { ISoundState } from '../state/sounds.state';
import { elableAllSounds, muteAllSoundsAction, setActiveSoundAction } from '../actions/sounds.actions';

export const soundsInitialState: ISoundState = {
  activeSound: null,
  playing:true
};

export const soundsReducer = createReducer(
  soundsInitialState,
  on(setActiveSoundAction, (state, { sound: activeSound }) => {
    return {
      ...state,
      activeSound,
    };
  }),
  on(muteAllSoundsAction, (state) => {
    return {
      ...state,
      playing: false,
    };
  }),
  on(elableAllSounds, (state) => {
    return {
      ...state,
      playing: true,
    };
  })
);
