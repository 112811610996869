import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IRole, Roles } from '@models/role.mode';
import { routeInfixes, routePaths } from '../app-routing/routes';
// import { KeycloakService } from './auth/keycloak.service';

type IToken = { sub: string; exp: number } | { preferred_username: string };

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    public jwtHelper: JwtHelperService,
    private router: Router // private _keycloakService: KeycloakService
  ) {}
  public setToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }
  public removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  }
  public login(token: string) {
    this.setToken(token);
    this.router.navigate([routePaths.game.mainMenu]);
  }
  public async logout() {
    // this._keycloakService.logout();
    this.removeToken();
    this.router.navigate([routePaths.auth.login]);
  }
  //
  public get jwtData(): IToken | {} | null {
    return this.jwtHelper.decodeToken(this.token);
  }
  public get username(): string {
    if (!this.jwtData) return '';
    if ('preferred_username' in this.jwtData) {
      return this.jwtData.preferred_username;
    }
    if ('sub' in this.jwtData) {
      return this.jwtData.sub;
    }
    return '';
  }

  public get token(): string {
    const localToken = localStorage.getItem('token');
    const sessionToken = sessionStorage.getItem('access_token');
    if (localToken) return localToken;
    if (sessionToken) return sessionToken;
    return sessionToken as string;
    // return sessionStorage.getItem(TOKEN_KEY) as string;
  }
  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.token);
  }
  public get isRunState(): boolean {
    return this.isAuthenticated();
    // && this.tokenState == 'allow';
  }
}
