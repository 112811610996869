import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, tap, TimeoutError } from 'rxjs';
import { retry, catchError, timeout } from 'rxjs/operators';
import { ApiError, IApiError } from '../interfaces/api.interfaces';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class GlobalHttpErrorHandler implements HttpInterceptor {
  constructor(
    private _router: Router,
    private authService: AuthenticationService,
    private notificationService: NotificationsService,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(3000),
      catchError((error: HttpErrorResponse | TimeoutError) => {
        let errorMessage: any = '';
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else {
            this.handleStatus(error.error, error.status);
            errorMessage = error.error;
          }
        } else if (error instanceof TimeoutError) {
          if (error.name == 'TimeoutError')
            this.notificationService.showToast(
              this.translate.instant(
                'AUTH.REGISTER.ERROR_MESSAGES.CONNECTION_TIMEOUT'
              ),
              'toast-alert',
              2000
            );
        }

        return throwError(() => errorMessage);
      })
    );
  }

  private async handleStatus(data: IApiError, statusCode: number) {
    console.error('handleStatus', data, statusCode);
    try {
    } catch (error) {}
    let showErrorMsg = true;
    if (statusCode === 401) {
      showErrorMsg = false;
      this.authService.logout();
    }
    if (statusCode === 409) {
      showErrorMsg = false;
      this.notificationService.showToast(
        this.translate.instant('AUTH.REGISTER.ERROR_MESSAGES.ADMINISTRATOR'),
        'toast-alert',
        2000
      );
    }
    if (showErrorMsg) console.log(showErrorMsg);
    //   this._notifications.showError(Error.title, Error.messageToString);
  }
}
