import { Injectable } from '@angular/core';
import { IActiveCountry, ICountry } from '@models/country.model';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, from, lastValueFrom, map, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { redirectToCountryPageAction } from '@actions/router.actions';
import { activateCountryAction } from '@actions/country.actions';
import { QuestService } from './quest.service';
import { questSelector$ } from '../store/selectors/quest.selectors';
@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private countriesUrl = '../../assets/data/countries.json';

  constructor(
    private http: HttpClient,
    private store: Store,
    private questService: QuestService
  ) {}
  getCountries(): Observable<ICountry[]> {
    return this.http.get<ICountry[]>(this.countriesUrl);
  }

  goToCountry(id: number): void {
    this.store.dispatch(activateCountryAction({ countryId: id }));
    this.store.dispatch(redirectToCountryPageAction());
  }

  // change input to country (from ngrx selector

  addCountryPointLabels(country: IActiveCountry) {
    country?.map.points.map((point) => {
      return this.store.select(questSelector$(point.id)).subscribe((quest) => {
        point.label = quest?.title;
      });
    });
  }
}
