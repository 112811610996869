import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { SoundActionsTypes } from '../actions/sounds.actions';
import { SoundsService } from '../../services/sounds.service';
import { SoundType } from '../../app-settings/app-sounds';
import { Store } from '@ngrx/store';
import { playingSoundSelector$ } from '../selectors/sounds.selector';
@Injectable()
export class SoundEffects {
  private audio!: HTMLAudioElement;
  private soundsDir = '/assets/sounds';

  changeSound$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SoundActionsTypes.SET_SOUND_ACTION),
        withLatestFrom(this._store.select(playingSoundSelector$)),
        mergeMap(([{ sound }, playing]: [{ sound: SoundType }, boolean]) => {
          if (!playing) return of({}) as any;
          if (sound === 'background') this.soundService.playbg();
          if (sound === 'buttonClick') this.soundService.playbtn();
          if (sound === 'quest') this.soundService.playquest();
          return of({}) as any;
        })
      );
    },
    { dispatch: false }
  );

  muteAllSounds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SoundActionsTypes.MUTE_ALL_SOUNDS_ACTION),
        tap(() => {
          this.soundService.muteAllSounds();
        })
      ),
    { dispatch: false }
  );
  pauseAllSounds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SoundActionsTypes.PAUSE_ALL_SOUNDS_ACTION),
        tap(() => {
          this.soundService.muteAllSounds();
        })
      ),
    { dispatch: false }
  );
  constructor(
    private _store: Store,
    private actions$: Actions,
    private soundService: SoundsService
  ) {}
}
