import { IMultiLanguage } from '@models/multiLang.model';
import { createAction, props } from '@ngrx/store';

export enum settingsActionTypes {
  SET_ACTIVE_LANGUAGE = '[SETTINGS] SET ACTIVE LANGUAGE',
  ENABLE_TOUR = '[TOUR] ENABLE TOUR',
  DISABLE_TOUR = '[TOUR] DISABLE TOUR',
}

export const setActiveLanguageAction = createAction(
  settingsActionTypes.SET_ACTIVE_LANGUAGE,
  props<{ langCode: keyof IMultiLanguage }>()
);

export const enableTourAction = createAction(settingsActionTypes.ENABLE_TOUR);
export const disableTourAction = createAction(settingsActionTypes.DISABLE_TOUR);
