import { setActiveMiniGame } from '@actions/games.actions';
import { createReducer, on } from '@ngrx/store';
import { IGamesState } from '@state/games.state';

export const timerInitialState: IGamesState = {
  activeGame: undefined,
};

export const gamesReducer = createReducer(
  timerInitialState,
  on(setActiveMiniGame, (state, { miniGame }) => {
    return {
      ...state,
      activeGame: miniGame,
    };
  })
);
