import { setQuestsAction } from '@actions/quest.actions';
import { routerActionTypes } from '@actions/router.actions';
import { setToolbarBackgroundHeightAction } from '@actions/toolbar.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { routePaths } from 'src/app/app-routing/routes';

@Injectable()
export class RouterEffects {
  // Redirection effects for every app page

  redirectToHomePageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_HOME_PAGE),
        tap(() => {
          this.router.navigate([routePaths.game.mainMenu]);
        })
      ),
    { dispatch: false }
  );

  redirectToQuestionnairePageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_QUESTIONNAIRE_PAGE),
        tap(() => {
          this.router.navigate([routePaths.game.questions]);
        })
      ),
    { dispatch: false }
  );

  redirectToContinentPageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_CONTINENT_PAGE),
        tap(() => {
          this.router.navigate([routePaths.game.worldMap]);
        })
      ),
    { dispatch: false }
  );

  redirectToCountryPageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_COUNTRY_PAGE),
        tap(({ countryCode }) => {
          this.router.navigate([routePaths.game.country]);
        })
      ),
    { dispatch: false }
  );

  redirectToQuestPageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_QUEST_PAGE),
        tap(({ questId }) => {
          this.router.navigate([routePaths.game.quest]);
        })
      ),
    { dispatch: false }
  );

  redirectToGamesListPageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_GAMES_LIST_PAGE),
        tap(() => {
          this.router.navigate([routePaths.game.miniGames.main]);
        })
      ),
    { dispatch: false }
  );

  redirectToProfilePageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_PROFILE_PAGE),
        tap(() => {
          this.router.navigate([routePaths.game.profile]);
        })
      ),
    { dispatch: false }
  );

  redirectToLogoutPageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_LOG_OUT_PAGE),
        tap(() => {
          this.router.navigate([routePaths.auth.login]);
        })
      ),
    { dispatch: false }
  );

  redirectToRegistrationPage1Effect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_REGISTRATION_PAGE_1),
        tap(() => {
          this.router.navigate([routePaths.auth.register.step_1]);
        })
      ),
    { dispatch: false }
  );

  redirectToRegistrationStep2Effect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActionTypes.REDIRECT_TO_REGISTRATION_PAGE_2),
        tap(() => {
          this.router.navigate([routePaths.auth.register.step_2]);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router) {}
}
