<div class="scale-option__container">
  <div
    class="scale-option__faces-container"
    [id]="introductory ? questionsIntroIds['#questions__slider-faces'] : ''"
  >
    <app-svg-loader
      *ngFor="let svg of svgArray; let svgIndex = index"
      [svgPath]="svg.svgPath"
      [color]="myIndex - 1 == svgIndex ? svg.color : '#ebebeb'"
      [size]="40"
    ></app-svg-loader>
  </div>
  <div
    class="scale-option__ion-range"
    [id]="introductory ? questionsIntroIds['#questions__scale-input'] : ''"
  >
    <ion-range
      aria-label="Custom range"
      [min]="0"
      [max]="10"
      [step]="'1'"
      (ionKnobMoveEnd)="myOnChange($event)"
      [pin]="true"
      [pinFormatter]="pinFormatter"
    ></ion-range>
  </div>

  <div class="scale-option__label-container">
    <div
      class="scale-option__label scale-option__label-start"
      [id]="
        introductory ? questionsIntroIds['#questions__min-number-label'] : ''
      "
    >
      <span> {{options?.[1]?.translatedLabel}} </span>
    </div>

    <div
      class="scale-option__label scale-option__label-end"
      [id]="
        introductory ? questionsIntroIds['#questions__max-number-label'] : ''
      "
    >
      <span> {{options?.[0]?.translatedLabel}}</span>
    </div>
  </div>
</div>
