<img class="auth-page__cloud-a" src="/assets/graphics/auth-page__cloud-a.svg" alt="cloud a" />
<img class="auth-page__cloud-b" src="/assets/graphics/auth-page__cloud-b.svg" alt="cloud b" />

<img class="auth-page__vector-a" src="/assets/graphics/auth-page__vector-a.svg" alt="vector a" />
<img class="auth-page__vector-b" src="/assets/graphics/auth-page__vector-b.svg" alt="vector b" />
<img class="auth-page__vector-c" src="/assets/graphics/auth-page__vector-c.svg" alt="vector c" />
<div class="auth-page">

  <div class="auth-page__logo-container">
    <h1 class="auth-page__heading" [translate]="'AUTH.TITLE'"></h1>
    <div class="auth-page__img-container">
      <img class="auth-page__logo" src="/assets/graphics/auth-page__logo.svg" alt="login logo" />
      <img class="auth-page__logo-shadow" src="/assets/graphics/auth-page__logo-shadow.svg" alt="login logo shadow" />
    </div>
    <div class="content">
      <p>Please open the application from a mobile device</p>

    </div>
  </div>
</div>