export const INTRO_IDS = {
  MAIN_MENU: {
    ids: {
      '#main-menu__level-display': 'main-menu__level-display',
      '#main-menu__profile-button': 'main-menu__profile-button',
      '#main-menu__games-list-button': 'main-menu__games-list-button',
      '#main-menu__logout-button': 'main-menu__logout-button',
      '#main-menu__start-game': 'main-menu__start-game',
      '#main-menu__settings-button': 'main-menu__settings-button',
      '#main-menu__energy-points-display': 'main-menu__energy-points-display',
      '#main-menu__energy-cooldown-display':
        'main-menu__energy-cooldown-display',
      '#main-menu__progress-ring-display': 'main-menu__progress-ring-display',
      '#main-menu__progress-percent-display':
        'main-menu__progress-percent-display',
      '#main-menu__questions-button': 'main-menu__questions-button',
      '#main-menu__menu-items-container': 'main-menu__menu-items-container',
      '#main-menu__reset-progress-button': 'main-menu__reset-progress-button',
      '#main-menu__full-screen': 'main-menu__full-screen',
    },
  },
  WORLD: {
    ids: {
      '#world__return-button': 'world__return-button',
      '#world__user-stats-ribbon': 'world__user-stats-ribbon',
      '#world__locked-country': 'world__locked-country',
      '#world__unlocked-country': 'world__unlocked-country',
      '#world__game-map': 'world__game-map',
    },
  },
  COUNTRY: {
    ids: {
      '#country__locked-quest': 'country__locked-quest',
      '#country__unlocked-quest': 'country__unlocked-quest',
      '#country__completed-quest': 'country__completed-quest',
    },
  },
  QUEST: {
    ids: {
      '#quest__city-label': 'quest__city-label',
      '#quest__description-container': 'quest__description-container',
      '#quest__go-to-game-button': 'quest__go-to-game-button',
    },
  },
  QUESTIONS: {
    ids: {
      '#questions__question-index': 'questions__question-index',
      '#questions__question-label': 'questions__question-label',
      '#questions__questionnaire-page': 'questions__questionnaire-page',
      '#questions__question-options': 'questions__question-options',
      '#questions__scale-label': 'questions__scale-label',
      '#questions__scale-input': 'questions__scale-input',
      '#questions__min-number-label': 'questions__min-number-label',
      '#questions__max-number-label': 'questions__max-number-label',
      '#questions__slider-faces': 'questions__slider-faces',
      '#questions__submit-questions': 'questions__submit-questions',
      '#questions__exit-questions': 'questions__exit-questions',
    },
  },
  NO_POINTS: {
    ids: {
      '#no-points__points-label': 'no-points__points-label',
      '#no-points__cooldown-timer': 'no-points__cooldown-timer',
      '#no-points__questions-button': 'no-points__questions-button',
    },
  },
  SETTINGS: {
    ids: {
      '#settings__language-selection': 'settings__language-selection',
      '#settings__sounds-container': 'settings__sounds-container',
      '#settings__close-button': 'settings__close-button',
    },
  },
  CONGRATULATIONS: {
    ids: {
      '#congratulations__energy-points-label':
        'congratulations__energy-points-label',
      '#congratulations__close-button': 'congratulations__close-button',
    },
  },
  MINI_GAME: {
    ids: {
      '#mini-game__game-container': 'mini-game__game-container',
      '#mini-game__close': 'mini-game__close',
    },
  },
  PROFILE: {
    ids: {
      '#profile__avatar-selection-button': 'profile__avatar-selection-button',
      '#profile__progress-statistics': 'profile__progress-statistics',
      '#profile__progress-bar': 'profile__progress-bar',
      '#profile__back-button': 'profile__back-button',
    },
  },
  AVATAR: {
    ids: {
      '#avatar__selected-avatar': 'avatar__selected-avatar',
      '#avatar__select-avatar': 'avatar__select-avatar',
      '#avatar__locked-avatar': 'avatar__locked-avatar',
      '#avatar__close-selector': 'avatar__close-selector',
    },
  },
  GAMES_LIST: {
    ids: {
      '#games-list__games-container': 'games-list__games-container',
      '#games-list__play-button': 'games-list__play-button',
    },
  },
} as const;
