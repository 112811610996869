import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IOptionUI } from '@models/question.model';
import { INTRO_IDS } from 'src/app/services/intro/data/intro_ids.intro';

@Component({
  selector: 'app-list-options',
  templateUrl: './list-options.component.html',
  styleUrls: ['./list-options.component.scss'],
})
export class ListOptionsComponent implements OnInit {
  @Input() options: IOptionUI[] = [];
  @Input() questionId: string = 'empty';
  @Input() listAnswer: any;
  @Input() introductory: boolean = false;
  @Output() responseEvent: EventEmitter<string> = new EventEmitter();
  questionsIntroIds = INTRO_IDS.QUESTIONS.ids;

  constructor() {}

  inputClicked(value: string) {
    console.log('value of list option:', value);
    this.responseEvent.emit(value);
  }

  ngOnInit() {}
}
