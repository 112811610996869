import { Injectable } from '@angular/core';
import { ParmsApiService } from '@api/params-api.service';
import { IGlobalParams } from '@models/params.model';
import { of, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ParamsService {
  constructor(private apiParams: ParmsApiService) {}

  getParams(): Observable<any> {
    return this.apiParams.getAPIGlobalParams();
  }
}
