<div
  class="no-questionnaire__header"
  [id]="NoPointsIntroIds['#no-points__points-label']"
>
  <h3>{{'QUESTIONS.COUNTDOWN.TITLE' | translate}}</h3>
</div>
<div class="no-questionnaire__image-container"></div>
<div
  class="no-questionnaire__countdown-container"
  [id]="NoPointsIntroIds['#no-points__cooldown-timer']"
  *ngIf="($remainingTimeInMilliSeconds | async ) as ms"
>
  <p class="no-questionnaire__countdown-description">
    {{'QUESTIONS.COUNTDOWN.TEXT' | translate}}
  </p>
  <app-countdown [timeFromEnd]="ms"></app-countdown>
</div>
<div [id]="NoPointsIntroIds['#no-points__questions-button']">
  <app-rounded-button
    (btnClick)="goToMainMenu()"
    background="primary"
    [text]="'QUESTIONS.COUNTDOWN.RETURN' | translate"
  ></app-rounded-button>
</div>
