import { IProgressExchange, IQuestsProgress } from '@models/progress.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IProgressState } from '@state/progress.state';
import { ITimerState } from '@state/timer.state';
import { IUserState } from '@state/user.state';

export const selectUserState = createFeatureSelector<IUserState>('user');
export const selectProgressState =
  createFeatureSelector<IProgressState>('progress');
export const selectTimerState = createFeatureSelector<ITimerState>('timer');

export const exchangeDataSelector$ = createSelector(
  selectUserState,
  selectProgressState,
  selectTimerState,
  (user, progress, timer): IProgressExchange => {
    return {
      username: '',
      avatarId: user.avatar,
      experience: user.experience,
      energyPoints: user.energyPoints,
      latest_energy_point_countdown_start:
        timer.latest_energy_point_countdown_start?.toISOString() || null,
      latest_questionnaire_at:
        timer.last_questionnaire_answered_time?.toISOString() || null,
      quests: progress.quests as IQuestsProgress,
    };
  }
);
