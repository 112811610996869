<ng-container [ngSwitch]="question.type">
  <div>
    <app-list-options
      [introductory]="question.order == 0"
      [listAnswer]="question.answer"
      [questionId]="question.id"
      *ngSwitchCase="'list'"
      [options]="question.options"
      (responseEvent)="questionEmitsResponse($event, question.id)"
    ></app-list-options>
  </div>
  <app-box-options
    [questionId]="question.id"
    [answer]="question.answer"
    *ngSwitchCase="'box'"
    [options]="question.options"
    (responseEvent)="questionEmitsResponse($event, question.id)"
  ></app-box-options>
  <app-scale-options
    [introductory]="question.order == 1"
    *ngSwitchCase="'scale'"
    [value]="value"
    [options]="question.options"
    (responseEvent)="questionEmitsResponse($event, question.id)"
  ></app-scale-options>
</ng-container>
