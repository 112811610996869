import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  get isMobile() {
    return this.platform.is('mobile');
  }

  get isIos() {
    return this.platform.is('ios');
  }
  get isAndroid() {
    return this.platform.is('android');
  }

  get isCapacitor() {
    return this.platform.is('capacitor');
  }

  get isDesktop() {
    return this.platform.is('desktop');
  }

  get isMobileWeb() {
    return this.platform.is('mobileweb');
  }

  get IsNativeAndroid() {
    return this.isAndroid && this.isCapacitor;
  }
  get IsNativeIOS() {
    return this.isIos && this.isCapacitor;
  }
  get IsBrowser() {
    return (this.isDesktop || this.isMobileWeb) && !this.isCapacitor;
  }

  get IsNative() {
    return this.IsNativeAndroid || this.IsNativeAndroid;
  }
  constructor(private platform: Platform) {}

  public checkDevice() {
    const devList = [
      'android',
      'capacitor',
      'cordova',
      'desktop',
      'electron',
      'hybrid',
      'ios',
      'ipad',
      'iphone',
      'mobile',
      'mobileweb',
      'phablet',
      'pwa',
      'tablet',
    ];
    devList.forEach((dev: any) => {
      const isDev = this.platform.is(dev);
      if (isDev) console.log(`Is ${dev}`);
    });
  }
}
