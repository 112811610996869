import {
  checkRegistrationCodeSuccess,
  clearRegistrationObjectAction,
  setAccessDetailsAction,
} from '@actions/registration.actions';
import { createReducer, on } from '@ngrx/store';
import { IRegistrationState } from '@state/registration.state';

export const registrationInitialState: IRegistrationState = {
  password: '',
  // username: '',
  registrationCode: '',
  hasGarmin: undefined,
};

export const registrationReducer = createReducer(
  registrationInitialState,
  on(setAccessDetailsAction, (state, { password }) => {
    return {
      ...state,
      // username,
      password,
    };
  }),
  on(checkRegistrationCodeSuccess, (state, { registrationCode }) => {
    return {
      ...state,
      registrationCode,
    };
  }),

  on(clearRegistrationObjectAction, () => {
    return registrationInitialState;
  })
);
