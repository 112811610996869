import { Injectable } from '@angular/core';
import { IUserExperienceProperties } from '@models/user.model';
import { environment } from 'src/environments/environment';
import { xpConstSelector$ } from '../store/selectors/params.selector';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  global_xp!: number;

  constructor(private store: Store) {
    this.store.select(xpConstSelector$).subscribe((payload) => {
      this.global_xp = payload;
    });
  }

  userExperienceProperties(experience: number): IUserExperienceProperties {
    let xp_const: number = 0;
    let userLevel: number = 0;
    let levelEndingXp: number = 0;
    let levelStartingXp: number = 0;
    let levelUpPercentage: number = 0;

    if (experience) {
      this.store.select(xpConstSelector$).subscribe;
      xp_const = this.global_xp;
      userLevel = Math.floor(Math.pow(experience / xp_const, 0.5)) + 1;
      levelStartingXp = Math.pow(userLevel - 1, 2) * xp_const;
      levelEndingXp = Math.pow(userLevel, 2) * xp_const;
      levelUpPercentage =
        Math.round(
          ((experience - levelStartingXp) / (levelEndingXp - levelStartingXp)) *
            10 *
            100
        ) / 10;
    }

    return {
      level: userLevel,
      experience: experience,
      percent: levelUpPercentage,
    };
  }
}
