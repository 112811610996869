import {
  disableTourAction,
  enableTourAction,
  setActiveLanguageAction,
} from '@actions/settings.actions';
import { createReducer, on } from '@ngrx/store';
import { ISettingsState } from '@state/settings.state';

export const settingsInitialState: ISettingsState = {
  activeLanguage: 'EN',
  showTour: false,
};

export const settingsReducer = createReducer(
  settingsInitialState,
  on(setActiveLanguageAction, (state, { langCode }) => {
    return {
      ...state,
      activeLanguage: langCode,
    };
  }),
  on(disableTourAction, (state) => {
    return {
      ...state,
      showTour: false,
    };
  }),
  on(enableTourAction, (state) => {
    return {
      ...state,
      showTour: true,
    };
  })
);
