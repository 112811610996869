import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ISoundState } from '../state/sounds.state';

export const selectSoundsState =
  createFeatureSelector<ISoundState>('sounds');

export const playingSoundSelector$ = createSelector(
  selectSoundsState,
  (soundState) => {
    return soundState.playing;
  }
);
