import { IQuest } from '@models/quest.model';
import { createReducer, on } from '@ngrx/store';
import { IContinentState } from '@state/continent.state';
import { IQuestState } from '@state/quest.state';

export const continentInitialState: IContinentState = {
  activeContinent: null,
  continents: [],
};

export const continentReducer = createReducer(continentInitialState);
