<div class="congratulations__ribbon">
  <!-- <h3>{{"CONGRATULATIONS.WELL_DONE" | translate}}</h3> -->
  <svg width="300" height="40" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path id="well_done" d="M 0 50 C 115 0, 200 0, 300 50" />
    </defs>
    <text>
      <textPath
        startOffset="50%"
        dominant-baseline="middle"
        text-anchor="middle"
        xlink:href="#well_done"
      >
        {{"CONGRATULATIONS.WELL_DONE" | translate}}
      </textPath>
    </text>
  </svg>
</div>
<div class="congratulations__thunder"></div>

<div class="congratulations__points-indicator">
  <p [id]="CongratulationsIntroIds['#congratulations__energy-points-label']">
    {{"CONGRATULATIONS.INFO" | translate }} {{$energy | async}}
    {{"CONGRATULATIONS.ENERGY_POINTS" | translate}}
  </p>
</div>
<div class="congratulations__turning-beams"></div>
<div class="congratulations__steady-beams"></div>

<div [id]="CongratulationsIntroIds['#congratulations__close-button']">
  <app-rounded-button
    (btnClick)="goToQuest()"
    background="primary"
    [text]="'CONGRATULATIONS.BUTTON' | translate"
  ></app-rounded-button>
</div>
