import { IResponseQuestionnaire } from '@models/responses.model';

export const filterResponses = (
  initialResponses: { [key: string]: any },
  responsesToExclude: { [key: string]: any }
): { [key: string]: IResponseQuestionnaire } => {
  let filteredResponses: { [key: string]: IResponseQuestionnaire } =
    initialResponses;
  Object.keys(responsesToExclude).forEach((key: string) => {
    if (filteredResponses[key]) delete filteredResponses[key];
    delete filteredResponses[key];
  });

  return filteredResponses;
};
