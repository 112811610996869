import { createAction, props } from '@ngrx/store';

export enum RegistrationActionTypes {
  // REGISTRATION CODE

  CHECK_REGISTRATION_CODE = '[REGISTRATION] CHECK CODE',
  CHECK_REGISTRATION_CODE_SUCCESS = '[REGISTRATION] CHECK CODE SUCCESS',
  CHECK_REGISTRATION_CODE_ERROR = '[REGISTRATION] CHECK CODE ERROR',

  // GARMIN
  GARMIN_REDIRECT = '[REGISTRATION] GARMIN REDIRECT',

  // ACCESS DETAILS
  SET_ACCESS_DETAILS = '[REGISTRATION] SET ACCESS DETAILS',
  CLEAR_REGISTRATION_OBJECT = '[REGISTRATION] CLEAR OBJECT',
}

// REGISTRATION CODE

export const checkRegistrationCode = createAction(
  RegistrationActionTypes.CHECK_REGISTRATION_CODE,
  props<{ registrationCode: string }>()
);
export const checkRegistrationCodeSuccess = createAction(
  RegistrationActionTypes.CHECK_REGISTRATION_CODE_SUCCESS,
  props<{ registrationCode: string; hasGarmin: boolean }>()
);
export const checkRegistrationCodeError = createAction(
  RegistrationActionTypes.CHECK_REGISTRATION_CODE_ERROR,
  props<{ registrationCode: string }>
);

export const garminRedirect = createAction(
  RegistrationActionTypes.GARMIN_REDIRECT,
  props<{ garminCode: string }>()
);

// ACCESS DETAILS
export const setAccessDetailsAction = createAction(
  RegistrationActionTypes.SET_ACCESS_DETAILS,
  props<{ password: string }>()
);

export const clearRegistrationObjectAction = createAction(
  RegistrationActionTypes.CLEAR_REGISTRATION_OBJECT
);
