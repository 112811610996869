import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRegistrationState } from '@state/registration.state';

export const selectRegistrationState =
  createFeatureSelector<IRegistrationState>('registration');

export const RegistrationCodeSelector = createSelector(
  selectRegistrationState,
  (registrationState) => {
    return {
      registrationCode: registrationState.registrationCode,
    };
  }
);
export const hasRegistrationCredentialsSelector = createSelector(
  selectRegistrationState,
  (registrationState) => {
    return Boolean(registrationState.password);
  }
);

export const hasGarminSelector = createSelector(
  selectRegistrationState,
  (registrationState) => {
    return {
      hasGarmin: registrationState.hasGarmin,
    };
  }
);
