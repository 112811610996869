import {
  progressActionTypes,
  retrieveProgressActionSuccess,
  setQuestsProgressAction,
} from '@actions/progress.actions';
import { Injectable } from '@angular/core';
import {
  IProgressExchange,
  IQuestProgress,
  IQuestsProgress,
} from '@models/progress.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  firstValueFrom,
  exhaustMap,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { QuestService } from 'src/app/services/quest.service';
import { questProgressSelector$ } from '../selectors/progress.selector';
import { ProgressApiService } from '@api/progress-api.service';
import { exchangeDataSelector$ } from '../selectors/exchange.selectors';
import { UserActionTypes } from '@actions/user.actions';
import { setLastUpdateTime, timerActionTypes } from '@actions/timer.actions';
import { convertCamelToSnake } from '../../@core/utils/object.parsers';
import { playedTimesToUnlockNextSelector$ } from '../selectors/params.selector';
import { userNameSelector } from '../selectors/user.selectors';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { lastUpdateTimestamp$ } from '../selectors/timer.selectors';

@Injectable()
export class ProgressEffects {
  // Activated when quest is played to add to timesPlayed
  questPlayedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressActionTypes.QUEST_PLAYED),
      withLatestFrom(this.store.select(playedTimesToUnlockNextSelector$)),
      switchMap(
        async ([data, timesToUnlockNext]: [{ questId: number }, number]) => {
          const questProgress: IQuestProgress | null = await firstValueFrom(
            this.store.select(questProgressSelector$(data.questId))
          );
          const questId: number = data.questId;
          let questsProgress: IQuestsProgress = {
            [questId]: {
              questId: questId,
              timesPlayed: questProgress!.timesPlayed + 1,
            },
          };
          // Unlocks succeeding quest
          if (questProgress!.timesPlayed + 1 === timesToUnlockNext) {
            const nextquestId = await this.questService.findNextquestId(
              questId
            );

            questsProgress = {
              ...questsProgress,
              ...{
                [nextquestId]: {
                  questId: nextquestId,
                  timesPlayed: 0,
                },
              },
            };
          }

          return setQuestsProgressAction({ questsProgress });
        }
      )
    )
  );

  retrieveProgressEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressActionTypes.RETRIEVE_PROGRESS),
      withLatestFrom(this.store.select(userNameSelector)),
      withLatestFrom(this.store.select(lastUpdateTimestamp$)),
      exhaustMap(
        ([[_, userName], frontendDate]: [[never, string], Date | null]) => {
          return this.progressApi.retrieveProgress().pipe(
            map((progressData) => {
              const userMatch =
                !userName || userName == this.auth.username || !userName;
              if (userMatch && frontendDate) {
                // Date Object Creation
                const backendDate = new Date(progressData.last_updated); // Example date from BE in UTC
                // Convert both dates to UTC time

                const backendDateUTC = backendDate.getTime();
                const frontendDateUTC =
                  frontendDate.getTime() -
                  frontendDate.getTimezoneOffset() * 60000;

                const feIsLatest = frontendDateUTC > backendDateUTC;

                if (feIsLatest) {
                  return retrieveProgressActionSuccess({
                    progressData,
                    replace: false,
                  });
                }
              }
              return retrieveProgressActionSuccess({
                progressData,
                replace: true,
              });
            }),
            catchError(() =>
              of({ type: progressActionTypes.RETRIEVE_PROGRESS_ERROR })
            )
          );
        }
      )
    )
  );

  updateProgressEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          progressActionTypes.UPDATE_PROGRESS,
          progressActionTypes.SET_QUEST_PROGRESS,
          UserActionTypes.DECREASE_ENERGY_POINTS,
          UserActionTypes.INCREASE_ENERGY_POINTS,
          UserActionTypes.SET_AVATAR_ACTION,
          UserActionTypes.SET_XP,
          timerActionTypes.SET_ENERGY_COUNTDOWN_START,
          timerActionTypes.SET_LAST_QUESTIONNAIRE_ANSWER_TIMESTAMP
        ),
        withLatestFrom(this.store.select(exchangeDataSelector$)),
        mergeMap(([_, data]: [any, IProgressExchange]) => {
          this.store.dispatch(setLastUpdateTime());
          return this.progressApi.updateProgress(convertCamelToSnake(data));
        })
      ),
    { dispatch: false }
  );

  constructor(
    private questService: QuestService,
    private auth: AuthenticationService,
    private actions$: Actions,
    private store: Store,
    private progressApi: ProgressApiService
  ) {}
}
