import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IGlobalParamsState } from '@state/params.state';

export const selectParamsState =
  createFeatureSelector<IGlobalParamsState>('params');

export const defaultLangKeySelector$ = createSelector(
  selectParamsState,
  (paramsState) => {
    return paramsState.defaultLangKey;
  }
);
export const energyCooldownSelector$ = createSelector(
  selectParamsState,
  (paramsState) => {
    return paramsState.energyCooldown;
  }
);
export const energyPointsCapSelector$ = createSelector(
  selectParamsState,
  (paramsState) => {
    return paramsState.energyPointsCap;
  }
);
export const playedTimesToUnlockNextSelector$ = createSelector(
  selectParamsState,
  (paramsState) => {
    return paramsState.playedTimesToUnlockNext;
  }
);
export const xpConstSelector$ = createSelector(
  selectParamsState,
  (paramsState) => {
    return paramsState.xp_const;
  }
);
export const timeBetweenQuestionnairesSelector$ = createSelector(
  selectParamsState,
  (paramsState) => {
    return paramsState.timeBetweenQuestionnaires;
  }
);
