import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IProgressExchange } from '@models/progress.model';
import { AuthenticationService } from '../services/authentication.service';
// import { dependenciesArray, questionsArray } from '../pages/questions/qdata';
type ApiResponse<T> = {
  data: T;
  errors: Array<string>;
  code: number;
};
@Injectable({ providedIn: 'root' })
export class ProgressApiService {
  private API_URL = environment.API_URL;
  private url = `${this.API_URL}/student/progress`;
  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  // ApiResponse
  retrieveProgress(): Observable<IProgressExchange | any> {
    return this.http
      .get<ApiResponse<IProgressExchange>>(
        `${this.url}/get?username=${this.auth.username}`
      )
      .pipe(
        map((progress) => {
          if (progress.data) {
            let obj: any = {};
            const quests = progress.data.quests;
            for (const questId in quests) {
              const val = quests[questId];
              obj[Number(questId)] = {
                questId: Number(val.questId),
                timesPlayed: Number(val.timesPlayed),
              };
            }
            return { ...progress.data, quests: obj };
          } else {
            return {};
          }
        })
      );
  }
  updateProgress(data: IProgressExchange) {
    data['username'] = this.auth.username;
    return this.http
      .post<any>(`${this.url}/update`, data)
      .pipe(map((progress) => progress || {}));
  }
}
