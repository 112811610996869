import { LoginDTO, LoginResponseDTO, RegisterDTO } from '@api/dtos/login.dto';
import { createAction, props } from '@ngrx/store';

export enum AuthActionsTypes {
  LOGIN_USER = '[USER] LOGIN',
  LOGIN_USER_SUCCESS = '[USER] LOGIN SUCCESS',
  LOGIN_USER_ERROR = '[USER] LOGIN ERROR',
  SET_USER_DETAILS = '[USER] SET DETAILS',
  LOGOUT_USER = '[USER] LOGOUT',
  REGISTER_USER = '[USER] REGISTER',
  REGISTER_USER_SUCCESS = '[USER] REGISTER SUCCESS',
  REGISTER_USER_ERROR = '[USER] REGISTER ERROR',
  CHANGE_PASSWORD = '[USER] CHANGE PASSWORD',
}

export const loginUserAction = createAction(
  AuthActionsTypes.LOGIN_USER,
  props<LoginDTO>()
);

export const loginUserActionSuccess = createAction(
  AuthActionsTypes.LOGIN_USER_SUCCESS,
  props<LoginResponseDTO>()
);

export const loginUserErrorAction = createAction(
  AuthActionsTypes.LOGIN_USER_ERROR
);

export const registerUserAction = createAction(AuthActionsTypes.REGISTER_USER);
export const changePasswordAction = createAction(
  AuthActionsTypes.CHANGE_PASSWORD,
  props<{ password: string }>()
);

export const registerUserActionSuccess = createAction(
  AuthActionsTypes.REGISTER_USER_SUCCESS,
  props<LoginResponseDTO>()
);

export const registerUserErrorAction = createAction(
  AuthActionsTypes.REGISTER_USER_ERROR
);

export const logoutUserAction = createAction(AuthActionsTypes.LOGOUT_USER);

export const setUserDetailsAction = createAction(
  AuthActionsTypes.SET_USER_DETAILS
);
