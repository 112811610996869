import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseQuestionnaire } from '@models/responses.model';
import { environment } from 'src/environments/environment';
import { questionnaires } from '../pages/questions/qdata';
import { Store } from '@ngrx/store';
import {
  confirmUnconfirmedResponsesAction,
  recallUnconfirmedResponsesAction,
  storeUnsentResponsesAction,
} from '@actions/responses.actions';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ApiResponse } from './dtos/response.dto';

// import { dependenciesArray, questionsArray } from '../pages/questions/qdata';

@Injectable({ providedIn: 'root' })
export class ResponsesApiService {
  private API_URL = environment.API_URL;

  private urlQ = `${this.API_URL}/student/questionnaires`;
  private url = `${this.API_URL}/student/answers`;
  constructor(private http: HttpClient, private store: Store) {}

  public postResponses(responses: { [key: string]: IResponseQuestionnaire }) {
    // const questionnairesAPI: IQuestionnaireAPI[] =
    //   questionnaires as IQuestionnaireAPI[];

    for (const [questionnaireTempID, questionnaire] of Object.entries(
      responses
    )) {
      this.http.post(this.url, questionnaire).subscribe({
        next: (data: any) => {
          this.store.dispatch(
            confirmUnconfirmedResponsesAction({
              questionnaire: { [questionnaireTempID]: questionnaire },
            })
          );
        },
        error: (err) => {
          this.store.dispatch(
            recallUnconfirmedResponsesAction({
              questionnaire: { [questionnaireTempID]: questionnaire },
            })
          );
        },
      });
    }
  }
  public getUserAnswers(): Observable<ApiResponse<any[]>> {
    return this.http.get(this.urlQ) as Observable<ApiResponse<any[]>>;
  }
}
