<ng-image-fullscreen-view
  [ngClass]="{
    'close-button-hidden': garminGuideIndex != garminGuideContent.length - 1,
    alternativeClose: !alwaysShowClose
  }"
  [images]="garminGuideContent"
  [imageIndex]="garminGuideIndex"
  [show]="showGarminGuide"
  (close)="closeEventHandler()"
  (nextImage)="nextImageEventHandler()"
  (prevImage)="previousImageEventHandler()"
></ng-image-fullscreen-view>
