import {
  addResponseAction,
  confirmUnconfirmedResponsesAction,
  storeUnsentResponsesAction,
  recallUnconfirmedResponsesAction,
} from '@actions/responses.actions';
import { createReducer, on } from '@ngrx/store';
import { IResponsesState } from '@state/responses.state';

export const responsesInitialState: IResponsesState = {
  unsent: {},
  unconfirmed: {},
};
import { filterResponses } from 'src/app/functions/responses.functions';

export const responsesReducer = createReducer(
  responsesInitialState,
  on(addResponseAction, (state, { responses }) => {
    state = {
      ...state,
      unsent: {
        ...state.unsent,
        [Math.floor(Math.random() * 100000 + 1) + '']: responses,
      },
    };
    return state;
  }),

  //  The storeUnsentResponsesAction is triggered from the sendUnsentResponsesEffect
  //  The reducer will take the unsentResponses and add the to the unconfirmed section of the responses slice
  on(storeUnsentResponsesAction, (state) => {
    state = {
      unsent: {},
      unconfirmed: {
        ...state.unsent,
        ...state.unconfirmed,
      },
    };
    return state;
  }),

  //  The recallUnconfirmedResponsesAction is triggered from the error section of the sendUnsentResponsesEffect
  //  The reducer will take the responses that the effect tried to post and transfer them from the unconfirmed section to the sent section back to the unsent section
  on(recallUnconfirmedResponsesAction, (state, { questionnaire }) => {
    state = {
      unsent: { ...state.unsent, ...questionnaire },
      unconfirmed: filterResponses({ ...state.unconfirmed }, questionnaire),
    };

    return state;
  }),

  //  The confirmUnconfirmedResponsesAction is triggered from the success section of the sendUnsentResponsesEffect
  //  The reducer will take the responses that the effect successfully posted and remove them from the unconfirmed section

  on(confirmUnconfirmedResponsesAction, (state, { questionnaire }) => {
    state = {
      unsent: { ...state.unsent },
      // unconfirmed: { ...state.unconfirmed },
      unconfirmed: filterResponses({ ...state.unconfirmed }, questionnaire),
    };
    return state;
  })
);
