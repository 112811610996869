export const convertSnakeToCamel = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertSnakeToCamel(item));
  }

  const camelObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = key.replace(/_([a-z])/g, (_, match) =>
        match.toUpperCase()
      );
      camelObj[camelKey] = convertSnakeToCamel(obj[key]);
    }
  }

  return camelObj;
};

export const convertCamelToSnake = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertCamelToSnake(item));
  }

  const snakeObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const snakeKey = key.replace(
        /[A-Z]/g,
        (match) => `_${match.toLowerCase()}`
      );
      snakeObj[snakeKey] = convertCamelToSnake(obj[key]);
    }
  }

  return snakeObj;
};
