import { createAction, props } from '@ngrx/store';
import { SoundType } from '../../app-settings/app-sounds';

export enum SoundActionsTypes {
  SET_SOUND_ACTION = '[SOUND] SET SOUND',
  PAUSE_ALL_SOUNDS_ACTION = '[SOUND] PAUSE SOUNDS',
  MUTE_ALL_SOUNDS_ACTION = '[SOUND] MUTE SOUNDS',
  ENABLE_ALL_SOUNDS_ACTION = '[SOUND] ENABLE SOUNDS',
}

export const setActiveSoundAction = createAction(
  SoundActionsTypes.SET_SOUND_ACTION,
  props<{ sound: SoundType }>()
);
export const pauseAllSoundsAction = createAction(
  SoundActionsTypes.PAUSE_ALL_SOUNDS_ACTION
);
export const muteAllSoundsAction = createAction(
  SoundActionsTypes.MUTE_ALL_SOUNDS_ACTION
);
export const elableAllSounds = createAction(
  SoundActionsTypes.ENABLE_ALL_SOUNDS_ACTION
);
