import { Injectable } from '@angular/core';
import { IMultiLanguage } from '@models/multiLang.model';
import {
  IChoiceAPI,
  IConfigAPI,
  IOption,
  IQuestionAPI,
  IQuestionUI,
  IQuestionnaireAPI,
  IQuestionnaireUI,
  ITranslationAPI,
} from '@models/question.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  constructor() {}
  public transformQuestionnairesAPItoUI(
    questionnairesApi: IQuestionnaireAPI[]
  ): IQuestionnaireUI[] {
    const questionnairesUI: IQuestionnaireUI[] = questionnairesApi.map(
      (questionnaireApi) => {
        const id = questionnaireApi._id;
        const description = questionnaireApi.description;
        const dependencies = questionnaireApi.dependencies;
        const questions = questionnaireApi.questions.map((q) => {
          return this.transformQuestionAPItoUI(q);
        });
        return { id, dependencies, description, questions };
      }
    );
    return questionnairesUI;
  }

  public transformQuestionAPItoUI(questionAPI: IQuestionAPI): IQuestionUI {
    const order: number = questionAPI.order;
    const id: string = questionAPI._id;
    const question = this.transformTranslationAPIToMultiLanguage(
      questionAPI.translations
    );
    const type = questionAPI.q_type;
    const options = this.getOptionsUiFromQuestionsAPI(questionAPI);

    return { order, id, question, type, options, activeIndex: 0 };
  }

  public getOptionsUiFromQuestionsAPI(questionAPI: IQuestionAPI) {
    let options!: IOption[];
    if (questionAPI.choices) {
      options = questionAPI.choices.map((c: IChoiceAPI) => {
        let option: IOption = {
          label: this.transformTranslationAPIToMultiLanguage(c.translations),
          value: '',
          id: c._id,
        };
        return option;
      });
    } else {
      options = questionAPI.config
        .map((c: IConfigAPI) => {
          let option: IOption = {
            label: this.transformTranslationAPIToMultiLanguage(c.translations),
            value: c.value.toString(),
            id: c.order.toString(),
          };
          return option;
        })
        .sort((a, b) => Number(b.id) - Number(a.id));
    }
    return options;
  }

  public transformTranslationAPIToMultiLanguage(
    translationAPI: ITranslationAPI[]
  ): IMultiLanguage {
    const result: IMultiLanguage = translationAPI.reduce(
      (acc: Partial<IMultiLanguage>, { language, text }) => {
        acc[language] = text;
        return acc;
      },
      {}
    );
    return result;
  }
}
