<div class="confirm__container">
  <h2 class="confirm__heading">{{confirmationMessage}}</h2>
  <div class="confirm__buttons-container">
    <div>
      <app-rounded-button
        text="{{confirmOption}}"
        background="primary"
        (btnClick)="confirm()"
      ></app-rounded-button>
    </div>
    <div>
      <app-rounded-button
        text="{{cancelOption}}"
        background="cancel"
        (btnClick)="cancel()"
      ></app-rounded-button>
    </div>
  </div>
</div>
