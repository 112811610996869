import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ITimerState } from '@state/timer.state';

export const selectTimerState = createFeatureSelector<ITimerState>('timer');

export const energyCountdownStartSelector$ = createSelector(
  selectTimerState,
  (timerState) => {
    return timerState.latest_energy_point_countdown_start;
  }
);
export const lastQuestionnaireAnsweredTimestamp$ = createSelector(
  selectTimerState,
  (timerState) => {
    return timerState.last_questionnaire_answered_time;
  }
);
export const lastUpdateTimestamp$ = createSelector(
  selectTimerState,
  (timerState) => {
    return timerState.last_update_time;
  }
);
