import { setParamsAction } from '@actions/params.actions';
import { createReducer, on } from '@ngrx/store';
import { IGlobalParamsState } from '@state/params.state';

export const paramsState: IGlobalParamsState = {
  xp_const: 1000000,
  energyCooldown: { days: 0, hours: 0, minutes: 10, seconds: 0 },
  energyPointsCap: 5,
  playedTimesToUnlockNext: 5,
  defaultLangKey: 'EN',
  timeBetweenQuestionnaires: { days: 0, hours: 1, minutes: 10, seconds: 0 },
};

export const paramsReducer = createReducer(
  paramsState,
  on(setParamsAction, (state, { params }) => {
    return {
      ...params,
    };
  })
);
