import { Injectable } from '@angular/core';
import { SOUNDS, SoundType } from '../app-settings/app-sounds';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  elableAllSounds,
  setActiveSoundAction,
} from '../store/actions/sounds.actions';

@Injectable({
  providedIn: 'root',
})
export class SoundsService {
  private bgSound: HTMLAudioElement;
  private questSound: HTMLAudioElement;
  private btnSound: HTMLAudioElement;

  private soundsDir = '/assets/sounds';

  sounds: { [key in SoundType]: BehaviorSubject<HTMLAudioElement> } = {
    background: new BehaviorSubject(new Audio()),
    buttonClick: new BehaviorSubject(new Audio()),
    quest: new BehaviorSubject(new Audio()),
  };
  constructor(private _store: Store) {
    this.bgSound = new Audio('/assets/sounds/bg.mp3');
    this.questSound = new Audio('/assets/sounds/quest.mp3');
    this.btnSound = new Audio('/assets/sounds/btn.mp3');
  }

  public LoadSounds() {
    for (const key in SOUNDS) {
      console.log('SOUNDS[key as Sounds]', SOUNDS[key as SoundType]);
      const sound = `${this.soundsDir}/${SOUNDS[key as SoundType]}`;
      const audioElement = new Audio(sound);
      audioElement.addEventListener('loadeddata', () => {
        this.sounds[key as SoundType] = new BehaviorSubject(audioElement);
        // let duration = audioElement.duration;
        // console.log('Loaded');
        // The duration variable now holds the duration (in seconds) of the audio clip
      });
    }
    //for each sound attach to dom
    //and add listeners for loaded
  }

  public muteAllSounds() {
    this.pause();
    // for (const key in SOUNDS) {
    //   this.sounds[key as SoundType].getValue().pause();
    // }
  }

  public elableAllSounds() {
    this._store.dispatch(elableAllSounds());
  }
  public playButtonSound() {
    this._store.dispatch(setActiveSoundAction({ sound: 'buttonClick' }));
  }
  public playBackgroundSound() {
    this._store.dispatch(setActiveSoundAction({ sound: 'background' }));
  }

  pause() {
    this.bgSound.pause();
    this.questSound.pause();
    this.btnSound.pause();
  }
  playbg(): void {
    this.bgSound.play();
  }
  playquest(): void {
    this.questSound.play();
  }
  playbtn(): void {
    this.btnSound.play();
  }
}
