const milliSecondsInASecond = 1000;
const secondsInAMinute = 60;
const minutesInAnHour = 60;
const hoursInADay = 24;

export class TimeDuration implements ITimeDuration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;

  constructor(
    obj: {
      days?: number;
      hours?: number;
      minutes?: number;
      seconds?: number;
    } = {}
  ) {
    this.days = obj.days ? obj.days : 0;
    this.hours = obj.hours ? obj.hours : 0;
    this.minutes = obj.minutes ? obj.minutes : 0;
    this.seconds = obj.seconds ? obj.seconds : 0;
  }

  getTime(): number {
    const daysMilliSeconds =
      this.days *
      hoursInADay *
      minutesInAnHour *
      secondsInAMinute *
      milliSecondsInASecond;
    const hoursMilliSeconds =
      this.hours * minutesInAnHour * secondsInAMinute * milliSecondsInASecond;
    const minutesMilliSeconds =
      this.minutes * secondsInAMinute * milliSecondsInASecond;
    const secondsMilliSeconds = this.seconds * milliSecondsInASecond;
    return (
      daysMilliSeconds +
      hoursMilliSeconds +
      minutesMilliSeconds +
      secondsMilliSeconds
    );
  }
}

export interface ITimeDuration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
