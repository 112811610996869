import { Injectable } from '@angular/core';
import { IContinent } from '@models/continent.model';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { redirectToContinentPageAction } from '@actions/router.actions';
import { setActiveContinentAction } from '@actions/continent.actions';
@Injectable({
  providedIn: 'root',
})
export class ContinentService {
  private continentsUrl = '../../assets/data/continents.json';

  constructor(private http: HttpClient, private store: Store) {}
  getContinents(): Observable<IContinent[]> {
    return this.http.get<IContinent[]>(this.continentsUrl);
  }

  getContinent(continentId: number): Observable<IContinent | undefined> {
    return this.http
      .get<IContinent[]>(this.continentsUrl)
      .pipe(
        map((continents) =>
          continents.find((continent) => continent.id == continentId)
        )
      );
  }

  // async getActiveContinentDetails(): Promise<IContinent | undefined> {
  //   const activeContinent$ = this.store.select(activeContinentSelector);
  //   const activeContinent = await firstValueFrom(activeContinent$);
  //   return this.continents.continents.find(
  //     (continent: IContinent) => continent.id === activeContinent.id
  //   );
  // }

  goToContinent(id: number): void {
    this.store.dispatch(setActiveContinentAction({ continentId: id }));
    this.store.dispatch(redirectToContinentPageAction());
  }
}
