import { createAction, props } from '@ngrx/store';
import { IQuest, ITemperedQuest } from '@models/quest.model';

export enum questActionTypes {
  // GET_ACTIVE_QUEST = '[ACTIVE QUEST] GET',
  // GET_ACTIVE_QUEST_SUCCESS = '[ACTIVE QUEST] GET',
  SET_ACTIVE_QUEST = '[ACTIVE QUEST] SET',
  CLEAR_ACTIVE_QUEST = '[ACTIVE QUEST] CLEAR',
  LOAD_QUESTS = '[QUESTS] LOAD',
  SET_QUESTS = '[QUESTS] SET',
}

// export const getActiveQuest = createAction(
//   activeQuestActionTypes.GET_ACTIVE_QUEST
// );
// export const getActiveSuccessQuest = createAction(
//   activeQuestActionTypes.GET_ACTIVE_QUEST_SUCCESS,
//   props<{ quest: IQuest }>()
// );

// Active quest id is stored in progress slice of ngrx store.
// dispatched: goToQuest of quest.service.ts
// reducer: in questReducer
export const setActiveQuestAction = createAction(
  questActionTypes.SET_ACTIVE_QUEST,
  props<{ questId: number }>()
);

// All quest are available in the questsList of the quests slice
// dispatched: on app init: app.component.ts
// effect: quest.effects.ts: loadQuestsEffect$
// succeeding action: setQuestsAction
export const loadQuestsAction = createAction(questActionTypes.LOAD_QUESTS);

// The success action of load quests that triggers the reducer
// dispatched: quest.effects.ts: loadCountriesEffect$
// reducer: quest.reducers.ts
export const setQuestsAction = createAction(
  questActionTypes.SET_QUESTS,
  props<{ quests: ITemperedQuest[] }>()
);

export const clearActiveQuestAction = createAction(
  questActionTypes.CLEAR_ACTIVE_QUEST
);
