import { createAction, props } from '@ngrx/store';
import { IActiveCountry, ICountry } from '@models/country.model';

export enum countryActionTypes {
  SET_ACTIVE_COUNTRY = '[ACTIVE COUNTRY] SET',
  LOAD_COUNTRIES = '[COUNTRIES] LOAD',
  SET_COUNTRIES = '[COUNTRIES] SET',
  ACTIVATE_COUNTRY = '[COUNTRY] ACTIVATE',
}

// The active state is stored as an activeID in the country slice
// dispatched: on goToCountry
// reducer: country.reducers.ts
export const activateCountryAction = createAction(
  countryActionTypes.ACTIVATE_COUNTRY,
  props<{ countryId: number }>()
);

// All countries are available in the countriesList of the country slice
// dispatched: on app init: app.component.ts
// effect: country.effects.ts: loadCountriesEffect$
// succeeding action: setCountriesAction
export const loadCountriesAction = createAction(
  countryActionTypes.LOAD_COUNTRIES
);

// The success action of load countries that triggers the reducer
// dispatched: country.effects.ts: loadCountriesEffect$
// reducer: country.reducers.ts
export const setCountriesAction = createAction(
  countryActionTypes.SET_COUNTRIES,
  props<{ countries: ICountry[] }>()
);
