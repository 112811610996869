import {
  AuthActionsTypes,
  loginUserActionSuccess,
  loginUserErrorAction,
  registerUserAction,
} from '@actions/auth.actions';
import {
  RegistrationActionTypes,
  checkRegistrationCodeError,
  checkRegistrationCodeSuccess,
} from '@actions/registration.actions';
import { setHasGarminAction } from '@actions/user.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@api/auth-api.service';
import { RegistrationApiService } from '@api/registration-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';
import { routePaths } from 'src/app/app-routing/routes';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UserService } from 'src/app/services/user.service';

@Injectable()
export class RegistrationEffects {
  checkRegistrationCodeEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationActionTypes.CHECK_REGISTRATION_CODE),
      exhaustMap(({ registrationCode }: { registrationCode: string }) => {
        return this.registrationApiService.checkIfValid(registrationCode).pipe(
          map((result) => {
            if (result.data == 'Valid: True') {
              const hasGarmin = false;
              this.store.dispatch(setHasGarminAction({ hasGarmin }));
              return checkRegistrationCodeSuccess({
                registrationCode,
                hasGarmin: hasGarmin,
              });
            } else {
              this.notificationService.showToast(
                this.translate.instant(
                  'AUTH.REGISTER.ERROR_MESSAGES.INVALID_REGISTRATION_CODE'
                ),
                'toast-alert',
                2000
              );
              return checkRegistrationCodeError();
            }
          }),
          catchError((error) => {
            this.notificationService.showToast(
              this.translate.instant(error.errors[0]),
              'toast-alert',
              2000
            );
            return of();
          })
        );
      })
    )
  );

  garminRedirectEffects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegistrationActionTypes.GARMIN_REDIRECT),
        tap(({ garminCode }: { garminCode: string }) => {
          this.registrationApiService.garminRedirect(garminCode);
        })
      ),
    { dispatch: false }
  );

  checkRegistrationCodeSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegistrationActionTypes.CHECK_REGISTRATION_CODE_SUCCESS),
        map(() => {
          this.router.navigate([routePaths.auth.register.step_2]);
        })
      ),
    { dispatch: false }
  );

  setAccessDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationActionTypes.SET_ACCESS_DETAILS),
      map(() => {
        return registerUserAction();
      })
    )
  );

  constructor(
    private registrationApiService: RegistrationApiService,
    private actions$: Actions,
    private authApi: AuthApiService,
    private notificationService: NotificationsService,
    private store: Store,
    private translate: TranslateService,
    private router: Router
  ) {}
}
