import { IQuestionnaireUI } from '@models/question.model';
import { createAction, props } from '@ngrx/store';

export enum questionsActionTypes {
  LOAD_QUESTIONNAIRES = '[QUESTIONNAIRE] LOAD QUESTIONNAIRES',
  SET_ACTIVE_QUESTIONNAIRES = '[QUESTIONNAIRE] SET ACTIVE QUESTIONNAIRES  ',
  TRANSLATE_QUESTIONS = '[QUESTIONNAIRE] TRANSLATE QUESTIONS',
  FILTER_QUESTIONS = '[QUESTIONNAIRE] FILTER QUESTIONS',
  STORE_RESPONSE = '[QUESTIONNAIRE] STORE RESPONSE',
  INCREASE_QUESTION_INDEX = '[QUESTIONNAIRE] INCREASE QUESTION INDEX',
  GET_UNANSWERED_QUESTIONS = '[QUESTIONNAIRE] GET UNANSWERED',
  EXTRACT_RESPONSES = '[QUESTIONNAIRE] INCREASE QUESTION INDEX',
  CLEAR_ACTIVE_QUESTIONNAIRES = '[QUESTIONNAIRE] CLEAR ACTIVE',
  QUESTIONNAIRE_OPEN_MODAL = '[QUESTIONNAIRE] OPEN MODAL',
}

export const loadQuestionnairesAction = createAction(
  questionsActionTypes.LOAD_QUESTIONNAIRES
);
export const setQuestionnairesAction = createAction(
  questionsActionTypes.SET_ACTIVE_QUESTIONNAIRES,
  props<{ questionnaires: IQuestionnaireUI[] }>()
);
export const openQuestionnaireModal = createAction(
  questionsActionTypes.QUESTIONNAIRE_OPEN_MODAL
);
// export const translateQuestionsAction = createAction(
//   continentActionTypes.TRANSLATE_QUESTIONS,
//   props<{ questions: IQuestion[]; dependencies: IDependency[] }>()
// );
// export const filterActiveQuestionnairesAction = createAction(
//   continentActionTypes.FILTER_QUESTIONS,
//   props<{ questions: IQuestion[]; dependencies: IDependency[] }>()
// );
// export const storeResponseAction = createAction(
//   continentActionTypes.STORE_RESPONSE,
//   props<{ questions: IQuestion[]; dependencies: IDependency[] }>()
// );
// export const increaseQuestionIndexAction = createAction(
//   continentActionTypes.INCREASE_QUESTION_INDEX,
//   props<{ questions: IQuestion[]; dependencies: IDependency[] }>()
// );

// export const extractResponsesAction = createAction(
//   continentActionTypes.EXTRACT_RESPONSES,
//   props<{ questions: IQuestion[]; dependencies: IDependency[] }>()
// );
// export const clearActiveQuestionnairesAction = createAction(
//   continentActionTypes.CLEAR_ACTIVE_QUESTIONNAIRES,
//   props<{ questions: IQuestion[]; dependencies: IDependency[] }>()
// );
