import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GarminGuideEventService {
  private eventSubject = new Subject<boolean>();
  eventObservable$ = this.eventSubject.asObservable();

  emitEvent(alwaysShowClose: boolean) {
    this.eventSubject.next(alwaysShowClose);
  }
}
