import { retrieveProgressActionSuccess } from '@actions/progress.actions';
import {
  decreaseEnergyPointsAction,
  increaseEnergyPointsAction,
  loadGarminCodeSuccessAction,
  setAvatarAction,
  setExperienceAction,
  setGarminCode,
  setHasGarminAction,
  setUsernameAction,
} from '@actions/user.actions';
import { createReducer, on } from '@ngrx/store';
import { IUserState } from '@state/user.state';

export const usersInitialState: IUserState = {
  avatar: '../../../assets/images/avatars/bear.svg',
  name: '',
  level: 1,
  percent: 0,
  experience: 0,
  energyPoints: 0,
  id: 'random_id',
  garminCode: undefined,
};

export const userReducer = createReducer(
  usersInitialState,

  on(setExperienceAction, (state, { experience, percent, level }) => {
    return {
      ...state,
      experience,
      percent,
      level,
    };
  }),
  on(setGarminCode, (state, { garminCode }) => {
    return {
      ...state,
      garminCode,
    };
  }),
  on(loadGarminCodeSuccessAction, (state, { garminCode }) => {
    return {
      ...state,
      garminCode,
    };
  }),
  on(retrieveProgressActionSuccess, (state, { progressData, replace }) => {
    if (replace) {
      return {
        ...state,
        avatar: progressData.avatarId,
        energyPoints: progressData.energyPoints,
      };
    }
    return state;
  }),
  on(setUsernameAction, (state, { name }) => {
    return {
      ...state,
      name,
    };
  }),

  on(decreaseEnergyPointsAction, (state, { decreaseAmount }) => {
    const latestEnergyPoints = state.energyPoints;
    if (latestEnergyPoints === 0) return state;
    return {
      ...state,
      energyPoints: latestEnergyPoints - decreaseAmount,
    };
  }),
  on(increaseEnergyPointsAction, (state, { increaseAmount }) => {
    const latestEnergyPoints = state.energyPoints;
    return {
      ...state,
      energyPoints: latestEnergyPoints + increaseAmount,
    };
  }),
  on(setAvatarAction, (state, { source }) => {
    return {
      ...state,
      avatar: source,
    };
  }),

  on(setHasGarminAction, (state, { hasGarmin }) => {
    return {
      ...state,
      hasGarmin: hasGarmin,
    };
  })
);
