import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IQuestState } from '@state/quest.state';

export const selectQuestState = createFeatureSelector<IQuestState>('quest');

export const activeQuestSelector$ = createSelector(
  selectQuestState,
  (activeQuestState) => {
    return activeQuestState.activeQuest;
  }
);

export const questsListSelector$ = createSelector(
  selectQuestState,
  (activeQuestState) => {
    return activeQuestState.quests;
  }
);
export const questSelector$ = (questId: number) =>
  createSelector(selectQuestState, (activeQuestState) => {
    return activeQuestState.quests.find((quest) => {
      return quest.id == questId;
    });
  });
