import { IResponseQuestionnaire } from '@models/responses.model';
import { createAction, props } from '@ngrx/store';

export enum responsesActionTypes {
  ADD_RESPONSES = '[RESPONSE] ADD',
  SEND_UNSENT_RESPONSES = '[RESPONSES] SEND UNSENT', // change to sync
  STORE_UNSENT_RESPONSES = '[RESPONSES] STORE UNSENT',
  SEND_UNCONFIRMED_RESPONSES = '[RESPONSES] SEND UNCONFIRMED',
  RECALL_UNCONFIRMED_RESPONSES = '[RESPONSES] RECALL UNCONFIRMED',
  CONFIRM_UNCONFIRMED_RESPONSES = '[RESPONSES] CONFIRM UNCONFIRMED',
}
// addResponseAction - is already there
// USED IN - questions.page submitResponses() method
// TRIGGERS - the on(addResponseAction) reducer
// USES - a single response

export const addResponseAction = createAction(
  responsesActionTypes.ADD_RESPONSES,
  props<{ responses: IResponseQuestionnaire }>()
);

// sendUnsentResponsesAction - the basic action: takes the responses in the unsent section of the slice and makes an http request ___ also empties
// USED IN - in the timer scheduler
// TRIGGERS - the sendUnsentResponsesEffect
// USES - the unsent responses
export const sendUnsentResponsesAction = createAction(
  responsesActionTypes.SEND_UNSENT_RESPONSES,
  props<{ responses: { [key: string]: IResponseQuestionnaire } }>()
);

// storeUnsentResponsesAction - When the sendUnsentResponsesAction is triggered the responses go temporary on the unconfirmed section
// USED IN - the sendUnsentResponsesEffect
// TRIGGERS - the on(storeUnsentResponsesAction) reducer
// USES - nothing
export const storeUnsentResponsesAction = createAction(
  responsesActionTypes.STORE_UNSENT_RESPONSES
);

// sendUnconfirmedResponsesAction - the backup action - fires on application start. If there are any responses in the unconfirmed section makes a post request
// USED IN - the onInitMethod
// TRIGGERS - the sendUnconfirmedResponsesEffect
// USES -  the unconfirmed responses
export const sendUnconfirmedResponsesAction = createAction(
  responsesActionTypes.SEND_UNCONFIRMED_RESPONSES,
  props<{ responses: { [key: string]: IResponseQuestionnaire } }>()
);
// recallUnconfirmedResponsesAction - If an error occurs the responses from the unconfirmed section go back to the unsent section
// USED IN - the error section of the sendUnsentResponsesEffect /// the error section of the sendUnconfirmedResponsesAction
// TRIGGERS - the on(recallUnconfirmedResponsesAction) reducer
// USES - the array of responses used in the send effects
export const recallUnconfirmedResponsesAction = createAction(
  responsesActionTypes.RECALL_UNCONFIRMED_RESPONSES,
  props<{ questionnaire: { [key: string]: IResponseQuestionnaire } }>()
);

// confirmUnconfirmedResponsesAction - If the post request is successful the the unconfirmed sections is filtered to exclude the responses included in the post request
// USED IN -  the success section of the sendUnsentResponsesEffect /// the success section of the sendUnconfirmedResponsesAction
// TRIGGERS - the on(confirmUnconfirmedResponsesAction) reducer
// USES - the array of responses used in the send effects
export const confirmUnconfirmedResponsesAction = createAction(
  responsesActionTypes.CONFIRM_UNCONFIRMED_RESPONSES,
  props<{ questionnaire: { [key: string]: IResponseQuestionnaire } }>()
);
