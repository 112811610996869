import { createAction } from '@ngrx/store';
export enum routerActionTypes {
  REDIRECT_TO_HOME_PAGE = '[HOME PAGE] REDIRECT',
  REDIRECT_TO_QUESTIONNAIRE_PAGE = '[QUESTIONNAIRE PAGE] REDIRECT',
  REDIRECT_TO_CONGRATULATIONS_PAGE = '[CONGRATULATIONS PAGE] REDIRECT',
  REDIRECT_TO_CONTINENT_PAGE = '[MAPS PAGE] REDIRECT',
  REDIRECT_TO_COUNTRY_PAGE = '[COUNTRY PAGE] REDIRECT',
  REDIRECT_TO_QUEST_PAGE = '[QUEST PAGE] REDIRECT',
  REDIRECT_TO_GAMES_LIST_PAGE = '[GAMES LIST PAGE] REDIRECT',
  REDIRECT_TO_SETTINGS_PAGE = '[SETTINGS PAGE] REDIRECT',
  REDIRECT_TO_PROFILE_PAGE = '[PROFILE PAGE] REDIRECT',
  REDIRECT_TO_LOG_OUT_PAGE = '[LOG OUT PAGE] REDIRECT',
  REDIRECT_TO_REGISTRATION_PAGE_1 = '[REGISTRATION PAGE] REDIRECT 1',
  REDIRECT_TO_REGISTRATION_PAGE_2 = '[REGISTRATION PAGE] REDIRECT 2',
}

// Actions that trigger router effects

export const redirectToQuestionnairePageAction = createAction(
  routerActionTypes.REDIRECT_TO_QUESTIONNAIRE_PAGE
);
export const redirectToContinentPageAction = createAction(
  routerActionTypes.REDIRECT_TO_CONTINENT_PAGE
);
export const redirectToCountryPageAction = createAction(
  routerActionTypes.REDIRECT_TO_COUNTRY_PAGE
);
export const redirectToQuestPageAction = createAction(
  routerActionTypes.REDIRECT_TO_QUEST_PAGE
);
export const redirectToGamesListPageAction = createAction(
  routerActionTypes.REDIRECT_TO_GAMES_LIST_PAGE
);
export const redirectToProfilePageAction = createAction(
  routerActionTypes.REDIRECT_TO_PROFILE_PAGE
);
export const redirectToLogoutPageAction = createAction(
  routerActionTypes.REDIRECT_TO_LOG_OUT_PAGE
);
export const redirectToSettingsPageAction = createAction(
  routerActionTypes.REDIRECT_TO_SETTINGS_PAGE
);
export const redirectToHomePageAction = createAction(
  routerActionTypes.REDIRECT_TO_HOME_PAGE
);
export const redirectToCongratulationsPageAction = createAction(
  routerActionTypes.REDIRECT_TO_CONGRATULATIONS_PAGE
);
export const redirectToRegistrationPage1Action = createAction(
  routerActionTypes.REDIRECT_TO_REGISTRATION_PAGE_1
);

export const redirectToRegistrationStep2Action = createAction(
  routerActionTypes.REDIRECT_TO_REGISTRATION_PAGE_2
);
