<button
  [type]="type"
  (click)="onClick()"
  [disabled]="isDisabled"
  class="rounded-button"
  [ngClass]="{
    'rounded-button--primary': background === 'primary',
    'rounded-button--secondary': background === 'secondary',
    'rounded-button--cancel': background === 'cancel'
  }"
>
  <div class="rounded-button__container">
    <span class="rounded-button__text">{{ buttonText }}</span>
  </div>
  <div *ngIf="arrow" class="rounded-button__arrow-container">
    <img
      class="rounded-button__arrow"
      src="../../../assets/graphics/rounded-button__arrow.svg"
      alt=""
    />
  </div>
</button>
