import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { setActiveSoundAction } from '../../store/actions/sounds.actions';

export type buttonBackground = 'primary' | 'secondary' | 'cancel';

@Component({
  selector: 'app-rounded-button',
  templateUrl: './rounded-button.component.html',
  styleUrls: ['./rounded-button.component.scss'],
})
export class RoundedButtonComponent {
  public buttonText = '';
  @Input() arrow: boolean = false;
  @Input() background: buttonBackground = 'primary';
  @Input()
  set text(name: string) {
    this.buttonText = name;
  }
  get name(): string {
    return this.buttonText;
  }

  @Input() color: string = '0068B4';
  @Input() type: string = 'button';
  @Input() isDisabled = false;
  @Output() btnClick = new EventEmitter();

  constructor(private store: Store) {}

  onClick() {
    this.btnClick.emit();
  }
}
