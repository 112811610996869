<div class="question-banner">
  <div class="question-banner__index-section">
    <p
      [id]="
        questionIndex == 0
          ? questionsIntroIds['#questions__question-index']
          : ''
      "
    >
      Question
      <span class="question-banner__question-index-span">
        {{ questionIndex }}</span
      >
      /
      <span class="question-banner__total-questions-span">{{
        totalQuestions
      }}</span>
    </p>
  </div>
  <div
    class="question-banner__question-container"
    [id]="questionsIntroIds['#questions__question-label']"
  >
    <p class="question-banner__question-paragraph">{{ question }}</p>
  </div>
</div>
