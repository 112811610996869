import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TimeDuration } from '@models/timer.model';
import { Store, select } from '@ngrx/store';
import { TimerService } from 'src/app/services/timer.service';
import {
  energyCountdownStartSelector$,
  lastQuestionnaireAnsweredTimestamp$,
} from 'src/app/store/selectors/timer.selectors';
import { combineLatest, map, Observable } from 'rxjs';
import { redirectToHomePageAction } from '@actions/router.actions';
import { INTRO_IDS } from 'src/app/services/intro/data/intro_ids.intro';
import {
  energyCooldownSelector$,
  timeBetweenQuestionnairesSelector$,
} from 'src/app/store/selectors/params.selector';

@Component({
  selector: 'app-no-questionnaire',
  templateUrl: './no-questionnaire.page.html',
  styleUrls: ['./no-questionnaire.page.scss'],
})
export class NoQuestionnairePage implements OnInit {
  $remainingTimeInMilliSeconds: Observable<number> = combineLatest([
    this.store.pipe(select(lastQuestionnaireAnsweredTimestamp$)),
    this.store.pipe(select(timeBetweenQuestionnairesSelector$)),
  ]).pipe(
    map(([selectorValue, energyCooldownValue]) => {
      if (selectorValue) {
        const time = this.timerService.calculateRemainingTimeMilliseconds(
          new Date(),
          selectorValue,
          new TimeDuration(energyCooldownValue)
        );
        return time;
      } else return 0;
    })
  );

  NoPointsIntroIds = INTRO_IDS.NO_POINTS.ids;

  constructor(
    private modalCtrl: ModalController,
    private store: Store,
    private timerService: TimerService
  ) {}

  ngOnInit() {}
  goToMainMenu() {
    this.store.dispatch(redirectToHomePageAction());
    return this.modalCtrl.dismiss(null, 'return');
  }
}
