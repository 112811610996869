// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { routePaths } from '../app/app-routing/routes';

export const environment = {
  xp_const: 200,
  production: false,
  energyCooldown: { minutes: 1 },
  energyPointsCap: 5,
  playedTimesToUnlockNext: 5,
  SENTRY_ID_:
    'https://dbe3743fa84d81b146915ab5881866ff@o4505912773443584.ingest.sentry.io/4505915040989184',
  defaultLangKey: 'EN',
  API_URL: `https://game-dev.synairg.eu/api/v2`,
  GARMIN_REDIRECTS: {
    web_url: `${window.location.origin}/${routePaths.game.mainMenu}`,
    android: 'keycloak://synairg.inlecom.eu',
    ios: 'synairg:keycloak',
  },
  GARMIN_URL: 'https://garmin.synairg.eu/login_with_garmin',
  keycloak: {
    keycloakUrl: 'https://synairg-ids-dev.inlecom.eu',
    realm: 'synairg',
    clientId: 'synairg_pcke',
    loginUrl: `${window.location.origin}/${routePaths.auth.login}`,
    redirect_uri: {
      ios: 'synairg:keycloak',
      android: 'keycloak://synairg.inlecom.eu',
      web: `${window.location.origin}/${routePaths.auth.oauth2}`,
    },
  },
};
