import { Component, OnInit } from '@angular/core';
import { IImageDTO, IImageSlide } from '@models/imageSlide.model';
import { HttpClient } from '@angular/common/http';
import { GarminGuideEventService } from 'src/app/services/helpers/garmin-guide-event.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { IMultiLanguage } from '@models/multiLang.model';
import { activeLanguageSelector$ } from 'src/app/store/selectors/settings.selectors';

@Component({
  selector: 'app-garmin-guide',
  templateUrl: './garmin-guide.component.html',
  styleUrls: ['./garmin-guide.component.scss'],
})
export class GarminGuideComponent implements OnInit {
  garminGuideContent: IImageSlide[] = [];
  showGarminGuide: boolean = false;
  garminGuideIndex: number = 0;
  alwaysShowClose: boolean = false;
  lang$ = this.store.select(activeLanguageSelector$);

  private garminGuideUrl = '../../assets/data/garmin-guide.json';
  subscription: Subscription;

  showLightbox(index: number) {
    this.garminGuideIndex = index;
    this.showGarminGuide = true;
  }

  closeEventHandler() {
    this.showGarminGuide = false;
    this.garminGuideIndex = -1;
  }
  constructor(
    private http: HttpClient,
    private store: Store,
    private garminGuideEventService: GarminGuideEventService
  ) {
    this.subscription = this.garminGuideEventService.eventObservable$.subscribe(
      (alwaysShowClose: boolean) => {
        this.openGarminGuide(0, alwaysShowClose);
      }
    );
  }

  async ngOnInit() {
    const lang: keyof IMultiLanguage = await firstValueFrom(this.lang$);
    this.http
      .get<IImageDTO[]>(this.garminGuideUrl)
      .subscribe((data: IImageDTO[]) => {
        this.garminGuideContent = data.map((slide) => {
          const title = slide.title[lang] || slide.title['EN'] || '';
          return { ...slide, title: title };
        });
      });
  }

  openGarminGuide(index: number, showClose: boolean) {
    this.alwaysShowClose = showClose;
    this.garminGuideIndex = index;
    this.showGarminGuide = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  nextImageEventHandler() {
    this.garminGuideIndex = this.garminGuideIndex + 1;
  }
  previousImageEventHandler() {
    this.garminGuideIndex = this.garminGuideIndex - 1;
  }
}
