import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IUserState } from '@state/user.state';

export const selectUserState = createFeatureSelector<IUserState>('user');

export const UserStatsSelector = createSelector(
  selectUserState,
  (userState) => {
    return {
      percentage: userState.percent,
      exp: userState.experience,
      level: userState.level,
    };
  }
);

export const userExperienceSelector = createSelector(
  selectUserState,
  (userState) => {
    return userState.experience;
  }
);
export const userLevelSelector = createSelector(
  selectUserState,
  (userState) => {
    return userState.level;
  }
);
export const userPercentSelector = createSelector(
  selectUserState,
  (userState) => {
    return userState.percent;
  }
);

export const userEnergyPointsSelector = createSelector(
  selectUserState,
  (userState) => {
    return userState.energyPoints;
  }
);

export const userNameSelector = createSelector(selectUserState, (userState) => {
  return userState.name;
});

export const userAvatarSelector = createSelector(
  selectUserState,
  (userState) => {
    return userState.avatar;
  }
);

export const garminCodeSelector$ = createSelector(
  selectUserState,
  (userState) => {
    return userState.garminCode;
  }
);
