import {
  continentActionTypes,
  setContinentsAction,
} from '@actions/continent.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs';
import { ContinentService } from 'src/app/services/continent.service';

@Injectable()
export class ContinentEffects {
  // Not active - will be used when and if continents are used
  loadContinentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continentActionTypes.LOAD_CONTINENTS),
      exhaustMap((data: any) =>
        this.continentService.getContinents().pipe(
          map((continents) => setContinentsAction({ continents })),
          catchError(() => {
            throw new Error(`Error in continents loading...`);
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private continentService: ContinentService
  ) {}
}
