import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  LoginDTO,
  LoginResponseDTO,
  RegisterDTO,
  RegisterResponseDTO,
} from './dtos/login.dto';
import { Observable, of, tap, map } from 'rxjs';
import { IRegistrationState } from '@state/registration.state';
import { IRegistrationPayload } from '@models/registration.model';
import { IGlobalParams } from '@models/params.model';

@Injectable({ providedIn: 'root' })
export class ParmsApiService {
  private API_URL = environment.API_URL;

  private url = `${this.API_URL}`;
  constructor(private http: HttpClient) {}

  public getAPIGlobalParams() {
    return this.http.get<IGlobalParams>(this.url + '/global/settings').pipe(
      map((payload: any) => {
        return payload.data;
      })
    );
    const params: IGlobalParams = {
      xp_const: 10000,
      energyCooldown: { days: 0, hours: 0, minutes: 0, seconds: 23 },
      timeBetweenQuestionnaires: { days: 0, hours: 0, minutes: 30, seconds: 0 },
      energyPointsCap: 67,
      playedTimesToUnlockNext: 3,
      defaultLangKey: 'GR',
    };
    return of(params);
  }

  // public getAPIQuestionnaires(): Observable<ApiResponse<IQuestionnaireAPI[]>> {
  //   return this.http.get(this.url) as Observable<
  //     ApiResponse<IQuestionnaireAPI[]>
  //   >;
  // }

  // public register(registrationData: IRegistrationPayload) {
  //   const registrationDTO: any = {
  //     // username: registrationData.username,
  //     password: registrationData.password,
  //     registration_code: registrationData.registrationCode,
  //     // garmin_id: registrationData.garminCode,
  //   };

  //   return this.http.post<RegisterResponseDTO>(
  //     this.url + '/student/register',
  //     registrationDTO
  //   );
  // }
}
