import {
  countryActionTypes,
  setCountriesAction,
} from '@actions/country.actions';
import { Injectable } from '@angular/core';
import { ICountry } from '@models/country.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';
import { CountryService } from 'src/app/services/country.service';
import { QuestService } from 'src/app/services/quest.service';

@Injectable()
export class CountryEffects {
  // Used on app init (app.component.ts)
  loadCountriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countryActionTypes.LOAD_COUNTRIES),
      exhaustMap((data: any) =>
        // Loads from json file
        this.countryService.getCountries().pipe(
          map((countries) => setCountriesAction({ countries })),
          catchError(() => {
            throw new Error(`Error in countries loading...`);
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private countryService: CountryService,
    private questsService: QuestService
  ) {}
}
