import { Component, Input, OnInit } from '@angular/core';
import { IMultiLanguage } from '@models/multiLang.model';
import { INTRO_IDS } from 'src/app/services/intro/data/intro_ids.intro';

@Component({
  selector: 'app-question-banner',
  templateUrl: './question-banner.component.html',
  styleUrls: ['./question-banner.component.scss'],
})
export class QuestionBannerComponent implements OnInit {
  @Input() totalQuestions: number = 0;
  @Input() questionIndex: number = 0;
  @Input() question?: string = 'What is your name?';
  questionsIntroIds = INTRO_IDS.QUESTIONS.ids;

  constructor() {}

  ngOnInit() {}
}
