import { IMultiLanguage } from '@models/multiLang.model';
import { ToolbarBackground } from '@models/toolbar.model';
import { createAction, props } from '@ngrx/store';

export enum toolbarActionTypes {
  SET_TOOLBAR_BACKGROUND_HEIGHT = '[TOOLBAR BACKGROUND HEIGHT] SET',
  SET_TOOLBAR_BACKGROUND_COLOR = '[TOOLBAR BACKGROUND COLOR] SET',
  SET_TOOLBAR_TITLE = '[TOOLBAR TITLE] SET',
  SET_TOOLBAR_BACK_ICON_HIDDEN = '[TOOLBAR BACK ICON HIDDEN] SET',
}

export const setToolbarBackgroundHeightAction = createAction(
  toolbarActionTypes.SET_TOOLBAR_BACKGROUND_HEIGHT,
  props<{ height: string }>()
);
export const setToolbarBackgroundColorAction = createAction(
  toolbarActionTypes.SET_TOOLBAR_BACKGROUND_COLOR,
  props<{ color: ToolbarBackground }>()
);
export const setToolbarTitleAction = createAction(
  toolbarActionTypes.SET_TOOLBAR_TITLE,
  props<{ title: string }>()
);
export const setBackIconHiddenAction = createAction(
  toolbarActionTypes.SET_TOOLBAR_BACK_ICON_HIDDEN,
  props<{ isBackIconHidden: boolean }>()
);
