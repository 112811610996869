import { setQuestionnairesAction } from '@actions/questions.actions';
import { createReducer, on } from '@ngrx/store';
import { IQuestionsState } from '@state/questions.state';

export const questionsInitialState: IQuestionsState = {
  questionnaires: [],
};

export const questionsReducer = createReducer(
  questionsInitialState,

  on(setQuestionnairesAction, (state, { questionnaires }) => {
    return {
      ...state,
      questionnaires: questionnaires,
    };
  })
);
