import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ISettingsState } from '@state/settings.state';

export const selectSettingsState =
  createFeatureSelector<ISettingsState>('settings');

export const activeLanguageSelector$ = createSelector(
  selectSettingsState,
  (settingsState) => {
    return settingsState.activeLanguage;
  }
);

export const ShowTourSelector$ = createSelector(
  selectSettingsState,
  (settingsState) => {
    return settingsState.showTour;
  }
);
