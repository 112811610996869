import { IQuestProgress } from '@models/progress.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IProgressState } from '@state/progress.state';
import { countriesListSelector$ } from './country.selectors';
import { questsListSelector$ } from './quest.selectors';

export const selectProgressState =
  createFeatureSelector<IProgressState>('progress');

export const questsProgressSelector$ = createSelector(
  selectProgressState,
  (progress) => {
    return progress.quests;
  }
);

export const latestProgressQuest$ = createSelector(
  selectProgressState,
  ({ quests }) => {
    const finalQuestId = Object.keys(quests).pop();
    return finalQuestId;
  }
);
export const questProgressSelector$ = (questId: number) =>
  createSelector(selectProgressState, (progress) => {
    return progress.quests[questId] ? progress.quests[questId] : null;
  });

export const questPlayedTimesSelector$ = (questId: number) =>
  createSelector(selectProgressState, (progress) => {
    return progress.quests[questId] ? progress.quests[questId].timesPlayed : 0;
  });

export const enrichedQuestsSelector$ = createSelector(
  questsProgressSelector$,
  questsListSelector$,
  (questsProgress /*  progress slice */, questsList /*  quests slice */) => {
    // deconstructing frozen array

    let quests = [...questsList];

    const unlockedQuestsIDs: number[] = (
      Object.values(questsProgress) as IQuestProgress[]
    ).map((item) => item.questId);

    const unlockedQuests = quests.map((quest) => {
      // default properties of enriched quest
      let unlocked = false;
      let timesPlayed = 0;

      // questProgress only contains quests that are unlocked. If quest id is in unlockedquestIds quest is unlocked
      if (unlockedQuestsIDs.includes(quest.id)) {
        unlocked = true;
        timesPlayed = questsProgress[quest.id].timesPlayed;
      }
      return { ...quest, unlocked, timesPlayed };
    });

    return unlockedQuests;
  }
);
