import { createAction, props } from '@ngrx/store';
import { IContinent } from '@models/continent.model';

export enum continentActionTypes {
  SET_ACTIVE_CONTINENT = '[ACTIVE CONTINENT] SET',
  LOAD_CONTINENTS = '[CONTINENTS] LOAD',
  SET_CONTINENTS = '[CONTINENTS] SET',
}

// dispatched: on goToContinent
// reducer: -
export const setActiveContinentAction = createAction(
  continentActionTypes.SET_ACTIVE_CONTINENT,
  props<{ continentId: number }>()
);
// dispatched: on app init: app.component.ts
// effect: continent.effects.ts: loadContinentsEffect$
// succeeding action: setContinentsAction
export const loadContinentsAction = createAction(
  continentActionTypes.LOAD_CONTINENTS
);

export const setContinentsAction = createAction(
  continentActionTypes.SET_CONTINENTS,
  props<{ continents: IContinent[] }>()
);
