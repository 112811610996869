import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { RangeCustomEvent } from '@ionic/angular';
import { IOption } from '@models/question.model';
import { Subject, debounce, debounceTime, takeUntil, tap } from 'rxjs';
import { INTRO_IDS } from 'src/app/services/intro/data/intro_ids.intro';
@Component({
  selector: 'app-scale-options',
  templateUrl: './scale-options.component.html',
  styleUrls: ['./scale-options.component.scss'],
})
export class ScaleOptionsComponent implements OnInit, OnDestroy {
  @Input() options?: IOption[] = [];
  @Input() min: number = 0;
  @Input() max: number = 10;
  @Input() introductory: boolean = false;
  @Input() value: number | undefined = 0;
  @Input() minLabel: string = 'No Symptoms';
  @Input() maxLabel: string = 'Worst Symptoms';
  @Output() responseEvent: EventEmitter<number> = new EventEmitter();
  myIndex: any;
  questionsIntroIds = INTRO_IDS.QUESTIONS.ids;

  private ngUnsubscribe$: Subject<any> = new Subject();
  intArray: number[] = [];
  svgArray: any[] = [
    {
      svgPath: '../../../assets/graphics/ranking-face__very-happy.svg',
      color: '#4ECD58',
    },
    {
      svgPath: '../../../assets/graphics/ranking-face__happy.svg',
      color: '#ADDB19',
    },
    {
      svgPath: '../../../assets/graphics/ranking-face__neutral.svg',
      color: '#FFCF2C',
    },
    {
      svgPath: '../../../assets/graphics/ranking-face__sad.svg',
      color: '#FF7F1E',
    },
    {
      svgPath: '../../../assets/graphics/ranking-face__very-sad.svg',
      color: '#EA344D',
    },
  ];

  constructor() {
    this.intArray = [...Array(this.max + 1).keys()];
  }
  myOnChange(ev: Event) {
    this.value = (ev as RangeCustomEvent).detail.value as number;
    this.myIndex = Math.ceil(this.value / 2) ? Math.ceil(this.value / 2) : 1;
    this.responseEvent.emit(this.value || 0);
  }

  ngOnInit() {
    this.myIndex = this.value ? Math.ceil(this.value / 2) : undefined;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.complete();
  }

  pinFormatter(value: number) {
    return `${value}`;
  }
}
