import {
  addResponseAction,
  sendUnconfirmedResponsesAction,
  sendUnsentResponsesAction,
} from '@actions/responses.actions';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IMultiLanguage } from '@models/multiLang.model';
import { IQuestionUI, IOptionUI, IDependency } from '@models/question.model';
import { Store } from '@ngrx/store';
import { firstValueFrom, map } from 'rxjs';
import {
  unconfirmedResponsesSelector$,
  unsentResponsesSelector$,
} from 'src/app/store/selectors/responses.selectors';
import { activeLanguageSelector$ } from 'src/app/store/selectors/settings.selectors';
import { userNameSelector } from 'src/app/store/selectors/user.selectors';
import { ModalController } from '@ionic/angular';
import { CongratulationsPage } from '../congratulations/congratulations.page';

import { ResponsesService } from 'src/app/services/responses.service';
import { increaseEnergyPointsAction } from '@actions/user.actions';
import {
  IResponseAnswer,
  IResponseQuestionnaire,
} from '@models/responses.model';
import { ConfirmPage } from '../confirm/confirm.page';
import { TranslateService } from '@ngx-translate/core';
import { INTRO_IDS } from 'src/app/services/intro/data/intro_ids.intro';
import { setLastQuestionnaireAnswerTime } from '@actions/timer.actions';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.page.html',
  styleUrls: ['./questions.page.scss'],
})
export class QuestionsPage implements OnInit {
  questionIntroIds = INTRO_IDS.QUESTIONS.ids;
  $user = this.store.select(userNameSelector);
  user: string = '';
  activeQuestion!: IQuestionUI;
  activeIndex: number = 0;
  onePage = false;
  lang$ = this.store.select(activeLanguageSelector$);
  targetElement!: HTMLElement;
  defaultLangKey = 'EN';
  filteredQuestions: IQuestionUI[] = [];
  myIndex = 0;
  dependenciesArray!: IDependency[];
  questionsArray!: IQuestionUI[];
  questionnaireTemplateId!: string;
  questionsIntroIds = INTRO_IDS.QUESTIONS.ids;
  hiddenIndexes: number[] = [];
  visibleIndexes: number[] = [];
  enableSubmit: boolean = false;

  constructor(
    private store: Store,
    private modalCtrl: ModalController,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    await this.translateQuestions();

    this.questionsArray = this.questionsArray.map((question) => {
      return { ...question, visible: false };
    });

    this.filterQuestions();

    this.activeQuestion = this.questionsArray[this.activeIndex];
  }

  clearQuestionsArray() {
    this.questionsArray.map((question) => {
      question.answer = undefined;
      return question;
    });
  }

  findHiddenAndVisible(): [number[], number[]] {
    const hidden: number[] = [];
    const visible: number[] = [];

    const filteredQuestions = this.questionsArray.reduce<IQuestionUI[]>(
      (acc, question, index) => {
        const dependency = this.dependenciesArray.find((dependency) => {
          return dependency.dependant == question.id;
        });
        if (!dependency) {
          acc.push(question);
          visible.push(index);
        } else {
          const isTrue = dependency.rules.some((rule) => {
            return acc.find((question) => {
              return (
                question.id == rule.question_id &&
                rule.options.some((option) => {
                  return option == question.answer;
                })
              );
            });
          });
          if (isTrue) {
            acc.push(question);
            visible.push(index);
          } else {
            hidden.push(index);
          }
        }

        return acc;
      },
      []
    );

    return [hidden, visible];
  }

  filterQuestions() {
    [this.hiddenIndexes, this.visibleIndexes] = this.findHiddenAndVisible();
    this.hiddenIndexes.forEach((index) => {
      this.questionsArray[index].visible = false;
      this.questionsArray[index].activeIndex = 0;
    });
    var activeIndex = 1;
    this.visibleIndexes.forEach((index) => {
      this.questionsArray[index].visible = true;
      this.questionsArray[index].activeIndex = activeIndex;
      activeIndex += 1;
    });
  }

  // assignOptionsId(question: IQuestion) {
  //   return question.options.map((option: IOption) => {
  //     option.id = this.getRandomId();
  //     return option;
  //   });
  // }

  getRandomId() {
    return Math.floor(Math.random() * 100000 + 1).toString();
  }
  // INCREASES THE QUESTION INDEX - USED IN THE SINGLE QUESTION PER PAGE TEMPLATE
  increaseIndex() {
    this.filterQuestions();
    if (this.activeIndex < this.questionsArray.length - 1) {
      this.activeIndex++;
      this.activeQuestion = this.questionsArray[this.activeIndex];
    }
  }

  async submitResponses() {
    const unansweredQuestionsExist = this.questionsArray
      .filter((q) => q.visible)
      .some((q) => q.answer == null || q.answer == undefined);

    if (unansweredQuestionsExist) {
      const id = this.questionsArray
        .filter((q) => q.visible)
        .findIndex(
          (q) =>
            q.order ==
            this.questionsArray.filter(
              (q) => q.visible && (q.answer == null || q.answer == undefined)
            )[0].order
        );

      const itemToScrollTo = document.getElementById('q-' + id);
      itemToScrollTo?.scrollIntoView({
        behavior: 'smooth',
      });
      return;
    }

    this.store.dispatch(
      addResponseAction({ responses: this.extractResponses() })
    );
    console.log('submiting responses');
    this.store.dispatch(setLastQuestionnaireAnswerTime());

    const unsentResponses = await firstValueFrom(
      this.store.select(unsentResponsesSelector$)
    );

    this.openCongratulationsModal();
    this.store.dispatch(
      sendUnsentResponsesAction({ responses: unsentResponses })
    );
    this.store.dispatch(increaseEnergyPointsAction({ increaseAmount: 1 }));
    this.clearQuestionsArray();
    this.return();
  }

  async onCloseClicked() {
    await this.openConfirmModal();
  }

  async translateQuestions() {
    const lang = await firstValueFrom(this.lang$);
    this.questionsArray = this.questionsArray.map((question) => {
      let extensibleQuestion = { ...question };
      extensibleQuestion.translatedQuestion =
        question.question[lang] || question.question['EN'];
      extensibleQuestion.options = this.translateOptions(
        lang,
        question.options
      );
      return extensibleQuestion;
    });
  }

  translateOptions(lang: keyof IMultiLanguage, options: IOptionUI[]) {
    let extensibleOptions = [...options];
    return extensibleOptions.map((option) => {
      let extensibleOption = { ...option };
      extensibleOption.translatedLabel =
        option.label[lang] || option.label['EN'];
      return extensibleOption;
    });
  }

  ngOnChanges(): void {}

  async openCongratulationsModal() {
    const modal = await this.modalCtrl.create({
      component: CongratulationsPage,
      backdropDismiss: false,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
    }
  }

  async openConfirmModal() {
    const confirmModal = await this.modalCtrl.create({
      component: ConfirmPage,
      cssClass: 'confirm-modal',
      componentProps: {
        confirmationMessage: this.translate.instant(
          'QUESTIONS.CONFIRMATION_MESSAGE'
        ),
        confirmOption: this.translate.instant('QUESTIONS.CONFIRM_OPTION'),
        cancelOption: this.translate.instant('QUESTIONS.CANCEL_OPTION'),
      },
    });
    confirmModal.present();

    confirmModal.onWillDismiss().then(({ data, role }) => {
      console.log({ data, role });
      if (role == 'confirm') {
        this.dismissAll();
      }
    });
  }

  return() {
    return this.modalCtrl.dismiss(null, 'return');
  }

  extractResponses(): IResponseQuestionnaire {
    const responsesArray: IResponseAnswer[] = this.questionsArray
      .filter((q) => q.visible)
      .map((question: IQuestionUI): IResponseAnswer => {
        if (question.type == 'scale') {
          return {
            value: question.answer || 'null',
            question_id: question.id,
          };
        } else {
          return {
            value: question.answer ? 'true' : 'false',
            question_id: question.id,
            choice_id: question.answer ? question.answer : '',
          };
        }
      });
    console.log('responsesArray', responsesArray);
    return {
      userID: '',
      template_id: this.questionnaireTemplateId,
      timestamp: Math.round(Date.now() / 1000).toString(),
      answers: responsesArray,
    };
  }

  checkIfAllQuestionsAnswered() {
    return this.questionsArray.filter((q) => q.visible).every((q) => q.answer);
  }

  async storeResponse(
    event: {
      event: any;
      questionId: string;
    },
    questionIndex: number
  ) {
    if (questionIndex !== -1) {
      this.questionsArray[questionIndex].answer = event.event.toString();
      this.questionsArray[questionIndex].answerDate = new Date();

      /**
       * The next blockcode is used to scroll to the next question
       */
      // setTimeout(() => {
      //   const nextQuestionIndex = `q-${++questionIndex}`;
      //   const nextQElement = document.getElementById(
      //     nextQuestionIndex
      //   ) as HTMLElement;
      //   try {
      //     nextQElement.scrollIntoView({
      //       behavior: 'smooth',
      //     });
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }, 600);
    }

    this.filterQuestions();
    this.enableSubmit = this.checkIfAllQuestionsAnswered();
  }

  dismissAll() {
    this.modalCtrl.dismiss('return').then((data) => {
      this.modalCtrl.dismiss('return');
    });
  }
}
