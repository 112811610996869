import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOptionUI } from '@models/question.model';
import { INTRO_IDS } from 'src/app/services/intro/data/intro_ids.intro';

@Component({
  selector: 'app-box-options',
  templateUrl: './box-options.component.html',
  styleUrls: ['./box-options.component.scss'],
})
export class BoxOptionsComponent implements OnInit {
  colorsLettersArray = [
    { letter: 'A', color: '#6EE280' },
    { letter: 'B', color: '#5FC9E0' },
    { letter: 'C', color: '#8F77EF' },
    { letter: 'D', color: '#EAAE55' },
    { letter: 'E', color: 'pink' },
    { letter: 'F', color: 'orange' },
    { letter: 'G', color: 'purple' },
    { letter: 'H', color: 'teal' },
  ];

  @Input() options?: IOptionUI[] = [];
  @Input() questionId: string = 'empty';
  @Input() answer?: string;
  @Output() responseEvent: EventEmitter<string> = new EventEmitter();
  questionsIntroIds = INTRO_IDS.QUESTIONS;

  constructor() {}

  ngOnInit() {}
  inputClicked(value: string) {
    this.answer = value;
    this.responseEvent.emit(value);
  }
}
