import { retrieveProgressActionSuccess } from '@actions/progress.actions';
import {
  setEnergyCountdownStart,
  setLastQuestionnaireAnswerTime,
  setLastUpdateTime,
} from '@actions/timer.actions';
import { createReducer, on } from '@ngrx/store';
import { ITimerState } from '@state/timer.state';
import { environment } from '../../../environments/environment';

export const timerInitialState: ITimerState = {
  latest_energy_point_countdown_start: null,
  last_questionnaire_answered_time: null,
  last_update_time: null,
};

export const timerReducer = createReducer(
  timerInitialState,
  on(setEnergyCountdownStart, (state, { countdownStart }) => {
    return {
      ...state,
      latest_energy_point_countdown_start: countdownStart,
    };
  }),

  on(setLastQuestionnaireAnswerTime, (state) => {
    return {
      ...state,
      last_questionnaire_answered_time: new Date(),
    };
  }),

  on(setLastUpdateTime, (state) => {
    return {
      ...state,
      last_update_time: new Date(),
    };
  }),

  on(retrieveProgressActionSuccess, (state, { progressData, replace }) => {
    if (replace) {
      const dateString: string | null =
        progressData.latest_energy_point_countdown_start;

      const questionTimestamp: string | null =
        progressData.latest_questionnaire_at;

      let countdownStart: Date | null = dateString
        ? new Date(dateString)
        : null;
      let lastQuestionAt: Date | null = questionTimestamp
        ? new Date(questionTimestamp)
        : null;

      const timeIsNull = !dateString;

      const energyIsNotCapped =
        progressData.energyPoints < environment.energyPointsCap;

      if (!timeIsNull) countdownStart = new Date(dateString);
      if (timeIsNull && energyIsNotCapped) countdownStart = new Date();

      return {
        ...state,
        latest_energy_point_countdown_start: countdownStart,
        last_questionnaire_answered_time: lastQuestionAt,
      };
    }
    return state;
  })
);
