import {
  loadGarminCodeSuccessAction,
  setExperienceAction,
  setUsernameAction,
  UserActionTypes,
} from '@actions/user.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import {
  userExperienceSelector,
  userNameSelector,
} from '../selectors/user.selectors';
import { progressActionTypes } from '@actions/progress.actions';
import { IProgressExchange } from '@models/progress.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AuthApiService } from '@api/auth-api.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Injectable()
export class UserEffects {
  increaseXP = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.INCREASE_XP),
      withLatestFrom(this.store.select(userExperienceSelector)),
      // adding increaseAmount to existing xp amount
      mergeMap(([{ increaseAmount }, latestExperienceAmount]) => {
        return of(
          setExperienceAction(
            this.userService.userExperienceProperties(
              latestExperienceAmount + increaseAmount
            )
          )
        );
      })
    );
  });

  retrieveExperience$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(progressActionTypes.RETRIEVE_PROGRESS_SUCCESS),
      mergeMap(
        ({
          progressData,
          replace,
        }: {
          progressData: IProgressExchange;
          replace: boolean;
        }) => {
          if (replace) {
            return of(
              setExperienceAction(
                this.userService.userExperienceProperties(
                  progressData.experience
                )
              )
            );
          }
          return of();
        }
      )
    );
  });

  retrieveUsername$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(progressActionTypes.RETRIEVE_PROGRESS_SUCCESS),
      mergeMap(
        ({
          progressData,
          replace,
        }: {
          progressData: IProgressExchange;
          replace: boolean;
        }) => {
          const name = this.auth.username;
          if (replace) {
            return of(setUsernameAction({ name }));
          }
          return of();
        }
      )
    );
  });

  deleteUserRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.DELETE_REQUEST),
        tap(() => {
          return this.authApi.delete().subscribe(() => {
            this.notificationService.showToast(
              'Deletion Requested',
              'toast-success',
              2000
            );
          });
        })
      ),
    { dispatch: false }
  );

  loadGarminCodeEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.LOAD_GARMIN_CODE_ACTION),
        withLatestFrom(this.store.select(userNameSelector)),
        switchMap(([_, username]) =>
          this.authApi
            .getUser(username)
            .pipe(
              map(({ data }: any) =>
                loadGarminCodeSuccessAction({ garminCode: data.garmin_id })
              )
            )
        )
      ),
    { dispatch: true } // Set to true to dispatch the actions
  );

  setGarminCodeEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.SET_GARMIN_CODE),
        switchMap((code) => {
          // Make your HTTP POST request here
          return this.authApi.setGarminID((code as any).garminCode).pipe(
            map((d) => {
              this.notificationService.showToast(
                'Garmin Successfully Registered',
                'toast-success',
                2000
              );
              return of();
            }),
            catchError((error) => {
              this.notificationService.showToast(
                'Garmin Register Error',
                'toast-alert',
                2000
              );
              return of();
            })
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private auth: AuthenticationService,
    private authApi: AuthApiService,
    private notificationService: NotificationsService
  ) {}
}
