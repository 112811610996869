import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  firstValueFrom,
  map,
  Observable,
  Subscription,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { TimerService } from 'src/app/services/timer.service';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit, OnChanges {
  @Input() timeFromEnd: number = 0;
  @Output() timerEnd = new EventEmitter();

  millisecondsRemaining$!: Observable<number>;

  @ViewChild('hours', { static: true }) hours!: ElementRef;
  @ViewChild('minutes', { static: true }) minutes!: ElementRef;
  @ViewChild('seconds', { static: true }) seconds!: ElementRef;

  constructor(private store: Store, private timerService: TimerService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.timeFromEnd !== null) {
      this.millisecondsRemaining$ = timer(0, 1000).pipe(
        map((n) => this.timeFromEnd - n * 1000),
        tap((n) => {
          if (n == 0) {
            this.timerEnd.emit('Timer Ended');
          }
        }),
        takeWhile((n) => n >= -1)
      );
    }
  }
  ngOnInit(): void {}
}
