export const gamePrefix = 'game';

const mainMenuInfix = 'main-menu';
const profileInfix = 'profile';
const worldMapInfix = 'world-map';
const countryInfix = 'country';
const questInfix = 'quest';
const questionsInfix = 'questions';
const newGameInfix = 'new-game';
const testingInfix = 'testing';
const miniGamesInfix = 'mini-games';
const miniGameContainer = 'game';
const unityGameInfix = 'unity';
const javascriptGameInfix = 'js';

const debugAsnwersInfix = 'debug/answers';

const mainMenuRoute = `${gamePrefix}/${mainMenuInfix}`;
const profileRoute = `${gamePrefix}/${profileInfix}`;
const worldMapRoute = `${gamePrefix}/${worldMapInfix}`;
const countryRoute = `${gamePrefix}/${countryInfix}`;
const questRoute = `${gamePrefix}/${questInfix}`;
const questionsRoute = `${gamePrefix}/${questionsInfix}`;
const newGameRoute = `${gamePrefix}/${newGameInfix}`;
const testingRoute = `${gamePrefix}/${testingInfix}`;
const miniGamesRoute = `${gamePrefix}/${miniGamesInfix}`;
const unityGameRoute = `${gamePrefix}/${miniGamesInfix}/${miniGameContainer}/${unityGameInfix}`;
const javascriptGameRoute = `${gamePrefix}/${miniGamesInfix}/${miniGameContainer}/${javascriptGameInfix}`;

const debugAnswersRoute = `${gamePrefix}/${debugAsnwersInfix}`;

export const authPrefix = 'auth';
export const registerPrefix = 'register';

const uuidInfix = 'uuid';
const registerInfix = 'register';
const loginInfix = 'login';
const oauth2Infix = 'oauth2';

const registrationStep1 = 'invitation-code';
const registrationStep2 = 'credentials';
const registrationStep3 = 'summary';

const uuidRoute = `${authPrefix}/${uuidInfix}`;
const registerRoute = `${authPrefix}/${registerInfix}`;
const loginRoute = `${authPrefix}/${loginInfix}`;
const oauth2Route = `${authPrefix}/${oauth2Infix}`;

const registrationPage1Route = `${registerRoute}/${registrationStep1}`;
const registrationStep2Route = `${registerRoute}/${registrationStep2}`;
const registrationPage3Route = `${registerRoute}/${registrationStep3}`;

export const routeInfixes = {
  auth: {
    uuid: uuidInfix,
    register: registerInfix,
    login: loginInfix,
    oauth2: oauth2Infix,
  },

  register: {
    step1: registrationStep1,
    step2: registrationStep2,
    step3: registrationStep3,
  },

  game: {
    mainMenu: mainMenuInfix,
    profile: profileInfix,
    worldMap: worldMapInfix,
    country: countryInfix,
    quest: questInfix,
    questions: questionsInfix,
    testing: testingInfix,
    miniGames: {
      list: miniGamesInfix,
      container: miniGameContainer,
      unityGame: unityGameInfix,
      jsGame: javascriptGameInfix,
    },
    debugAnswers: debugAsnwersInfix,
  },
};

export const routePaths = {
  game: {
    mainRoute: `${gamePrefix}`,
    mainMenu: mainMenuRoute,
    profile: profileRoute,
    worldMap: worldMapRoute,
    country: countryRoute,
    quest: questRoute,
    questions: questionsRoute,
    newGame: newGameRoute,
    debugAnswers: debugAnswersRoute,
    testing: testingRoute,
    miniGames: {
      main: miniGamesRoute,
      unityGame: (unityGameId: number) => `${unityGameRoute}/${unityGameId}`,
      javascriptGame: (javscriptGameId: number) =>
        `${javascriptGameRoute}/${javscriptGameId}`,
    },
  },
  auth: {
    mainRoute: `${authPrefix}`,
    login: loginRoute,
    register: {
      step_1: registrationPage1Route,
      step_2: registrationStep2Route,
      step_3: registrationPage3Route,
    },
    oauth2: oauth2Route,
    uuid: uuidRoute,
  },
};

// world-map
// questions
// quest
