import { Subject } from 'rxjs';

export class LoadingService {
  private loadingState = new Subject<boolean>();
  private startTime?: number;

  get loadingState$() {
    return this.loadingState.asObservable();
  }

  showLoading() {
    this.loadingState.next(true);
    this.startTime = Date.now(); // Record the start time as a timestamp
  }

  hideLoading() {
    const elapsedTime = Date.now() - (this.startTime || 0); // Calculate elapsed time
    const minimumDuration = 1000; // 1 second in milliseconds

    if (elapsedTime < minimumDuration) {
      setTimeout(() => {
        this.loadingState.next(false);
      }, minimumDuration - elapsedTime);
    } else {
      this.loadingState.next(false);
    }
  }
}
