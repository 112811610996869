import {
  activateCountryAction,
  setCountriesAction,
} from '@actions/country.actions';

import { createReducer, on } from '@ngrx/store';
import { ICountryState } from '@state/country.state';

export const countryInitialState: ICountryState = {
  countries: [],
  activeCountryID: 1,
};

export const countryReducer = createReducer(
  countryInitialState,

  on(setCountriesAction, (state, { countries }) => {
    return {
      ...state,
      countries,
    };
  }),
  on(activateCountryAction, (state, { countryId }) => {
    return {
      ...state,
      activeCountryID: countryId,
    };
  })
);
