<div class="box-options">
  <div
    class="box-option"
    *ngFor="let option of options; let optionIndex = index"
    [ngClass]="answer == option.id ? 'option_selected' : 'option_not_selected'"
  >
    <input
      (click)="inputClicked(option.id)"
      type="radio"
      [(ngModel)]="answer"
      id="{{ option.id }}"
      [name]="questionId"
      [value]="option.id"
    />
    <label for="{{ option.id }}" class="box-option__letter-label">
      <div class="box-option__letter-label-container">
        <div
          class="box-option__letter"
          [ngStyle]="{ background: colorsLettersArray[optionIndex].color }"
        >
          {{ colorsLettersArray[optionIndex].letter }}
        </div>
        {{ option.translatedLabel }}
      </div>

      <div
        class="box-option__selected-mask"
        [ngStyle]="{ background: colorsLettersArray[optionIndex].color }"
      ></div>
    </label>
  </div>
</div>
