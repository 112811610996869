import {
  redirectToContinentPageAction,
  redirectToHomePageAction,
  redirectToQuestPageAction,
} from '@actions/router.actions';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { INTRO_IDS } from 'src/app/services/intro/data/intro_ids.intro';
import { userEnergyPointsSelector } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-congratulations',
  templateUrl: './congratulations.page.html',
  styleUrls: ['./congratulations.page.scss'],
})
export class CongratulationsPage implements OnInit {
  constructor(private store: Store, private modalCtrl: ModalController) {}

  $energy = this.store.select(userEnergyPointsSelector);
  CongratulationsIntroIds = INTRO_IDS.CONGRATULATIONS.ids;

  ngOnInit() {}

  goToQuest() {
    this.store.dispatch(redirectToHomePageAction());
    return this.modalCtrl.dismiss(null, 'return');
  }
}
