import {
  setBackIconHiddenAction,
  setToolbarBackgroundColorAction,
  setToolbarBackgroundHeightAction,
  setToolbarTitleAction,
} from '@actions/toolbar.actions';
import { createReducer, on } from '@ngrx/store';
import { IToolbarState } from '@state/toolbar.state';

export const toolbarInitialState: IToolbarState = {
  backgroundColor: 'primary',
  backgroundHeight: '200px',
  title: 'MAIN_MENU.TITLE',
  isBackIconHidden: false,
};

export const toolbarReducer = createReducer(
  toolbarInitialState,
  on(setToolbarBackgroundHeightAction, (state, { height }) => {
    return {
      ...state,
      backgroundHeight: height,
    };
  }),
  on(setToolbarBackgroundColorAction, (state, { color }) => {
    return {
      ...state,
      backgroundColor: color,
    };
  }),
  on(setToolbarTitleAction, (state, { title }) => {
    return {
      ...state,
      title: title,
    };
  }),
  on(setBackIconHiddenAction, (state, { isBackIconHidden }) => {
    return {
      ...state,
      isBackIconHidden: isBackIconHidden,
    };
  })
);
// setToolbarBackgroundHeightAction
// setToolbarBackgroundColorAction
// setToolbarTitleAction
// setBackIconHiddenAction

// height
// color
// title
// isBackIconHidden
