import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { continentReducer } from './continent.reducers';
import { countryReducer } from './country.reducers';
import { progressReducer } from './progress.reducers';
import { questReducer } from './quest.reducers';
import { settingsReducer } from './settings.reducers';
import { timerReducer } from './timer.reducers';
import { userReducer } from './user.reducers';
import { responsesReducer } from './responses.reducers';
import { toolbarReducer } from './toolbar.reducers';
import { questionsReducer } from './questions.reducers';
import { soundsReducer } from './sounds.reducer';
import { gamesReducer } from './game.reducers';
import { registrationReducer } from './registration.reducers';
import { paramsReducer } from './params.reducers';

export const appReducers: ActionReducerMap<AppState, any> = {
  user: userReducer,
  quest: questReducer,
  settings: settingsReducer,
  timer: timerReducer,
  country: countryReducer,
  continent: continentReducer,
  progress: progressReducer,
  responses: responsesReducer,
  toolbar: toolbarReducer,
  questions: questionsReducer,
  sounds: soundsReducer,
  games: gamesReducer,
  registration: registrationReducer,
  params: paramsReducer,
};
