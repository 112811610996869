<div class="top-bg top-bg--primary"></div>
<div class="questions__header">
  <h2 class="questions__heading">{{ "QUESTIONS.TITLE" | translate }}</h2>
  <div
    [id]="questionsIntroIds['#questions__exit-questions']"
    class="questions-page__close-button"
  >
    <app-close-button (click)="onCloseClicked()"></app-close-button>
  </div>
</div>

<ng-container *ngIf="( lang$ | async) as lang">
  <div
    class="questions-page__multi-question-container"
    *ngIf="!onePage"
    [id]="questionIntroIds['#questions__questionnaire-page']"
  >
    <div
      [id]="'q-'+questionIndex"
      class="questions-page__multi-question-instance"
      *ngFor="let question of questionsArray; let questionIndex = index; let isLast=last"
      [ngClass]="{ 'last-element': isLast }"
    >
      <ng-container *ngIf="question.visible ">
        <app-question-banner
          class="toolbarTargetElement"
          #questionsTitle
          [questionIndex]="question.activeIndex"
          [totalQuestions]="visibleIndexes.length"
          [question]="(question.question[lang] || question.question['EN'] ) "
        ></app-question-banner>

        <app-question
          [question]="question"
          [lang]="lang"
          (emitQuestionResponse)="storeResponse($event, questionIndex)"
        ></app-question>
      </ng-container>
    </div>

    <app-rounded-button
      type="submit"
      [text]="('QUESTIONS.SUBMIT' | translate)"
      [arrow]="true"
      background="secondary"
      (click)="submitResponses()"
    ></app-rounded-button>
  </div>

  <div
    class="questions-page__single-question-container"
    *ngIf="onePage && activeQuestion "
  >
    <app-question-banner
      class="toolbarTargetElement"
      [questionIndex]="activeIndex"
      [totalQuestions]="visibleIndexes.length"
      [question]="(activeQuestion.question[lang] || activeQuestion.question['EN'] ) || 'What is your name?'"
    ></app-question-banner>

    <app-rounded-button
      type="submit"
      [text]="questionsArray.length==activeIndex+1?('QUESTIONS.SUBMIT' | translate): ('QUESTIONS.NEXT' | translate) "
      [arrow]="true"
      background="secondary"
      (click)="questionsArray.length==activeIndex+1?submitResponses():increaseIndex() "
      [isDisabled]="!activeQuestion.answer"
    ></app-rounded-button>
    <div
      class="questions-page__submit-button"
      [id]="questionsIntroIds['#questions__submit-questions']"
    >
      <app-rounded-button
        type="submit"
        [text]="questionsArray.length==activeIndex+1?('QUESTIONS.SUBMIT' | translate): ('QUESTIONS.NEXT' | translate) "
        [arrow]="true"
        background="secondary"
        (click)="questionsArray.length==activeIndex+1?submitResponses():increaseIndex() "
        [isDisabled]="!activeQuestion.answer"
      ></app-rounded-button>
    </div>
  </div>
</ng-container>
