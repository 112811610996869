<div class="list-options" [id]="introductory ? questionsIntroIds['#questions__question-options'] : ''">
  <div *ngFor="let option of options" class="list-option" [ngClass]="
      listAnswer == option.id ? 'option_selected' : 'option_not_selected'
    ">
    <input type="radio" id="{{ option.id }}" name="{{ questionId }}" [(ngModel)]="listAnswer" [value]="option.id"
      (ngModelChange)="inputClicked($event)" />
    <label for="{{ option.id }}">
      <span>{{ option.translatedLabel }}</span>

      <!-- <div class="list-option__selected-mask">
        <img
          class="list-option__shape-a"
          src="../../../assets/graphics/list-option__shape-a.svg"
          alt="decoration shape a"
        />
        <img
          class="list-option__shape-b"
          src="../../../assets/graphics/list-option__shape-b.svg"
          alt="decoration shape b"
        />
      </div> -->
      <div class="list-option__icons">
        <img class="list-option__tick" src="../../../assets/graphics/list-option__tick.svg" alt="" />
        <img class="list-option__circle" src="../../../assets/graphics/list-option__circle.svg" alt="" />
      </div>
    </label>
  </div>
</div>