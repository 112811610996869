import { createAction, props } from '@ngrx/store';

export enum timerActionTypes {
  SET_ENERGY_COUNTDOWN_START = '[FREE ENERGY COUNTDOWN] SET START',
  SET_LAST_QUESTIONNAIRE_ANSWER_TIMESTAMP = '[LAST QUESTIONNAIRE ANSWER TIME] SET',
  SET_LAST_UPDATE_TIMESTAMP = '[LAST UPDATE TIME] SET',
  CHECK_ENERGY_COUNTDOWN_TIMER = '[ENERGY COUNTDOWN TIMER] CHECK',
  CHECK_QUESTIONNAIRE_COUNTDOWN_TIMER = '[QUESTIONNAIRE COUNTDOWN TIMER] CHECK',
  CHECK_LAST_UPDATED = '[LAST UPDATE] CHECK',
}

// Countdown timer is defined with two parameters: the static countdown duration (set in environment) and the start date of each countdown.
// For example a timer starting in 15:13:03 that has a duration of {minutes:10}
// All other properties of the countdown can be found using the two parameters and the now date.

// dispatched: setCountdownStart of countdownService (used in countDownHandler ). Triggered by a 1000ms timer.
export const setEnergyCountdownStart = createAction(
  timerActionTypes.SET_ENERGY_COUNTDOWN_START,
  props<{ countdownStart: Date | null }>()
);
export const setLastQuestionnaireAnswerTime = createAction(
  timerActionTypes.SET_LAST_QUESTIONNAIRE_ANSWER_TIMESTAMP
);

export const setLastUpdateTime = createAction(
  timerActionTypes.SET_LAST_UPDATE_TIMESTAMP
);

export const checkQuestionnaireCountdownTimerAction = createAction(
  timerActionTypes.CHECK_ENERGY_COUNTDOWN_TIMER,
  props<{
    countdownStartString: string | null;
  }>()
);

export const checkLastUpdateAction = createAction(
  timerActionTypes.CHECK_ENERGY_COUNTDOWN_TIMER,
  props<{
    clientLastUpdate: string | null;
    serverLastUpdate: string | null;
  }>()
);
