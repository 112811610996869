import { routerActionTypes } from '@actions/router.actions';
import {
  setActiveLanguageAction,
  settingsActionTypes,
} from '@actions/settings.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs';
import { QuestService } from 'src/app/services/quest.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/settings/app.settings';

@Injectable()
export class SettingsEffects {
  setActiveLanguageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActionTypes.SET_ACTIVE_LANGUAGE),
        tap(({ langCode }) => {
          this.translate.use(langCode);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private store: Store
  ) {}
}
