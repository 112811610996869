import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  LoginDTO,
  LoginResponseDTO,
  RegisterDTO,
  RegisterResponseDTO,
} from './dtos/login.dto';
import { Observable, of, tap } from 'rxjs';
import { IRegistrationState } from '@state/registration.state';
import { IRegistrationPayload } from '@models/registration.model';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  private API_URL = environment.API_URL;

  private url = `${this.API_URL}`;
  constructor(
    private http: HttpClient,
    private _authService: AuthenticationService
  ) {}

  public login(loginDto: LoginDTO) {
    var loginForm: any = new FormData();

    // loginForm.append('usr', loginDto.username);
    // loginForm.append('psw', loginDto.password);

    let queryParams = new HttpParams()
      .append('usr', loginDto.username)
      .append('psw', loginDto.password);

    return this.http.get<LoginResponseDTO>(this.url + '/auth/keycloak/jwt', {
      params: queryParams,
    });
  }
  public delete() {
    return this.http.delete(this.url + '/user/data/delete');
  }

  public changePassword(username: string, password: string) {
    return this.http.put(this.url + '/student/update', {
      username: username,
      password: password,
    });
  }

  public setGarminID(garminCode: string) {
    console.log('SAVE GARMIN', garminCode);
    return this.http.put(this.url + '/student/update', {
      username: this._authService.username,
      garmin_id: garminCode,
    });
  }

  public getUser(username: string) {
    let queryParams = new HttpParams().append('username', username);

    return this.http.get(this.url + '/student/get', {
      params: queryParams,
    });
  }

  public register(registrationData: IRegistrationPayload) {
    const registrationDTO: any = {
      // username: registrationData.username,
      password: registrationData.password,
      registration_code: registrationData.registrationCode,
      // garmin_id: registrationData.garminCode,
    };

    return this.http.post<RegisterResponseDTO>(
      this.url + '/student/register',
      registrationDTO
    );
  }
}
