import {
  clearActiveQuestAction,
  setActiveQuestAction,
  setQuestsAction,
} from '@actions/quest.actions';
import { IQuest } from '@models/quest.model';
import { createReducer, on } from '@ngrx/store';
import { IQuestState } from '@state/quest.state';
import { retrieveProgressActionSuccess } from '../actions/progress.actions';
export const questInitialState: IQuestState = {
  quests: [],
  activeQuest: null,
};

export const questReducer = createReducer(
  questInitialState,
  on(setActiveQuestAction, (state, { questId }) => {
    const quest = state.quests.find((quest) => quest.id === questId);
    if (!quest) {
      throw new Error(`Unable to find quest with id:  '${questId}'`);
    }
    return {
      ...state,
      activeQuest: quest,
    };
  }),

  on(setQuestsAction, (state, { quests }) => {
    return {
      ...state,
      quests,
    };
  }),

  on(clearActiveQuestAction, (state) => {
    return {
      ...state,
      activeQuest: null,
    };
  }),
  on(
    retrieveProgressActionSuccess,
    (state, { progressData: { quests }, replace }) => {
      if (replace) {
        const activateQuestIds = Object.keys(quests).map((val) => Number(val));
        const QUEST_LIST = state.quests.map((q: any) => {
          const extQ = { ...q };
          if (activateQuestIds.includes(extQ.id)) {
            extQ['unlocked'] = true;
            extQ['timesPlayed'] = quests[extQ.id].timesPlayed;
          }
          return { ...extQ };
        });
        return {
          ...state,
          quests: QUEST_LIST,
        };
      }

      return state;
    }
  )
);
