import { IQuestionnaireUI } from '@models/question.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IQuestionsState } from '@state/questions.state';

export const selectQuestionsState =
  createFeatureSelector<IQuestionsState>('questions');

// export const activeQuestionnairesSelector$ = createSelector(
//   selectQuestionsState,
//   (questions) => {
//     return questions.activeQuestionnaires;
//   }
// );
export const questionnairesSelector$ = createSelector(
  selectQuestionsState,
  (questions) => {
    const q = questions.questionnaires as IQuestionnaireUI[];
    return q;
  }
);

// export const activeQuestionIDSelector$ = createSelector(
//   selectQuestionsState,
//   (questions) => {
//     return questions.activeQuestionId;
//   }
// );
