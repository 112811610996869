import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IResponsesState } from '@state/responses.state';

export const selectResponsesState =
  createFeatureSelector<IResponsesState>('responses');

export const responsesSelector$ = createSelector(
  selectResponsesState,
  (responses) => {
    return responses;
  }
);

export const unsentResponsesSelector$ = createSelector(
  selectResponsesState,
  (responses) => {
    return responses.unsent;
  }
);

export const unconfirmedResponsesSelector$ = createSelector(
  selectResponsesState,
  (responses) => {
    return responses.unconfirmed;
  }
);
