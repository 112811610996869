import { createAction, props } from '@ngrx/store';
import { IUser, IUserExperienceProperties } from '@models/user.model';

export enum UserActionTypes {
  INCREASE_XP = '[USER] INCREASE XP',
  SET_XP = '[USER] SET XP',
  SET_LEVEL = '[USER] SET XP',
  SET_LEVEL_UP_PERCENT = '[USER] SET LEVEL UP PERCENT',
  LOG_IN_USER = '[USER] LOG IN',
  LOG_IN_USER_SUCCESS = '[USER] LOG IN SUCCESS',
  DECREASE_ENERGY_POINTS = '[USER] DECREASE ENERGY POINTS',
  INCREASE_ENERGY_POINTS = '[USER] INCREASE ENERGY POINTS',
  SET_USERNAME_ACTION = '[USER] SET NAME',
  SET_AVATAR_ACTION = '[USER] SET AVATAR',
  DELETE_REQUEST = '[USER] DELETE REQUEST',
  SET_GARMIN_CODE = '[USER] SET GARMIN CODE',
  SET_HAS_GARMIN_ACTION = '[USER] SET HAS GARMIN',
  LOAD_GARMIN_CODE_ACTION = '[USER] LOAD GARMIN CODE',
  LOAD_GARMIN_CODE_SUCCESS_ACTION = '[USER] LOAD GARMIN CODE SUCCESS',
}

// dispatched: currently for testing purposes in increaseExperience function of profile.page
// effect: UserEffects - increaseXP
// succeeding action: setExperienceAction
export const increaseExperienceAction = createAction(
  UserActionTypes.INCREASE_XP,
  props<{ increaseAmount: number }>()
);

export const loadGarminCodeAction = createAction(
  UserActionTypes.LOAD_GARMIN_CODE_ACTION
);

export const loadGarminCodeSuccessAction = createAction(
  UserActionTypes.LOAD_GARMIN_CODE_SUCCESS_ACTION,
  props<{ garminCode: string }>()
);

export const setUsernameAction = createAction(
  UserActionTypes.SET_USERNAME_ACTION,
  props<{ name: string }>()
);
// GARMIN
export const setGarminCode = createAction(
  UserActionTypes.SET_GARMIN_CODE,
  props<{ garminCode: string }>()
);
// dispatched: UserEffects - increaseXP
// reducer: userReduce

export const setExperienceAction = createAction(
  UserActionTypes.SET_XP,
  props<IUserExperienceProperties>()
);

// dispatched: in questionnaire, in profile(testing), and as an award after countdown
// reducer: user.reducers.ts
export const increaseEnergyPointsAction = createAction(
  UserActionTypes.INCREASE_ENERGY_POINTS,
  props<{ increaseAmount: number }>()
);

// dispatched: in quest page useEnergyPoints, in profile(testing)
// reducer: user.reducers.ts
export const decreaseEnergyPointsAction = createAction(
  UserActionTypes.DECREASE_ENERGY_POINTS,
  props<{ decreaseAmount: number }>()
);
export const setAvatarAction = createAction(
  UserActionTypes.SET_AVATAR_ACTION,
  props<{ source: string }>()
);
export const deleteUserRequestAction = createAction(
  UserActionTypes.DELETE_REQUEST
);

export const setHasGarminAction = createAction(
  UserActionTypes.SET_HAS_GARMIN_ACTION,
  props<{ hasGarmin: boolean }>()
);
