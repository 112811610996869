import { createAction, props } from '@ngrx/store';
import { MiniGame } from 'src/app/services/games.service';

export enum gamesActionTypes {
  SET_ACTIVE_MINI_GAME = '[MINI GAMES] SET ACTIVE',
}

export const setActiveMiniGame = createAction(
  gamesActionTypes.SET_ACTIVE_MINI_GAME,
  props<{ miniGame?: MiniGame }>()
);
