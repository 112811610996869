import { Injectable } from '@angular/core';

import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings';
import { PlatformService } from '../platform.service';
import { AlertController } from '@ionic/angular';

type permissionRes = Promise<{
  status: boolean;
}>;

@Injectable({
  providedIn: 'root',
})
export class AppPermissionsService {
  constructor(
    private _plt: PlatformService,
    private alertController: AlertController
  ) {}

  public async PropmptOpenSettings() {
    const alert = await this.alertController.create({
      header: 'Notification Permissions',
      message:
        'Please enable notifications for the save the world (Synair-g) application. Click the settings button in order to navigate to the application settings page.',
      cssClass: ['permission-alert'],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Settings',
          role: 'settings',
          handler: async () => {
            this.OpenAppSettings();
          },
        },
      ],
    });

    await alert.present();
  }

  private OpenAppSettings(): permissionRes {
    if (this._plt.IsNativeAndroid) return this.OpenAndroidSettings();
    if (this._plt.IsNativeIOS) return this.OpenIOSNotifications();
    throw new Error('Unable to open settings. Cant specify app.');
  }

  private OpenAndroidSettings(): permissionRes {
    return NativeSettings.openAndroid({
      option: AndroidSettings.ApplicationDetails,
    });
  }

  private OpenIOSNotifications(): permissionRes {
    return NativeSettings.openIOS({
      option: IOSSettings.App,
    });
  }
}
