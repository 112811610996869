import {
  continentActionTypes,
  setContinentsAction,
} from '@actions/continent.actions';
import {
  openQuestionnaireModal,
  questionsActionTypes,
  setQuestionnairesAction,
} from '@actions/questions.actions';
import { Injectable } from '@angular/core';
import { QuestionsApiService } from '@api/questions-api.service';
import { IQuestionAPI, IQuestionnaireUI } from '@models/question.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  exhaustMap,
  firstValueFrom,
  map,
  mergeMap,
  tap,
} from 'rxjs';
import { QuestionsService } from 'src/app/services/questions.service';
import { questionnairesSelector$ } from '../selectors/questions.selectors';
import { QuestionsPage } from 'src/app/pages/questions/questions.page';
import { ModalController } from '@ionic/angular';
import { TimerService } from 'src/app/services/timer.service';
import { NoQuestionnairePage } from 'src/app/pages/no-questionnaire/no-questionnaire.page';

@Injectable()
export class QuestionsEffects {
  loadQuestionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questionsActionTypes.LOAD_QUESTIONNAIRES),
      mergeMap(() =>
        this.questionsApiService.getAPIQuestionnaires().pipe(
          map((questionnairesApi) => {
            const { data } = questionnairesApi;
            const questionnairesUI: IQuestionnaireUI[] =
              this.questionsService.transformQuestionnairesAPItoUI(data);
            return setQuestionnairesAction({
              questionnaires: questionnairesUI,
            });
          })
        )
      )
    )
  );
  openQuestionnairesModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openQuestionnaireModal),
        tap(async () => {
          const QuestionaireIsNotAvailable =
            await this.timeService.QuestionnaireIsNotAvailable();
          if (QuestionaireIsNotAvailable) {
            this.openNoQuestionsModal();
            return;
          }

          const questionnaires: IQuestionnaireUI[] = await firstValueFrom(
            this.store.select(questionnairesSelector$)
          );

          const questionnaire = questionnaires[0];

          const modal = await this.modalCtrl.create({
            component: QuestionsPage,
            cssClass: 'questions-modal',
            componentProps: {
              questionnaireTemplateId: questionnaire.id,
              questionsArray: questionnaire.questions,
              dependenciesArray: questionnaire.dependencies,
            },
            backdropDismiss: false,
          });
          modal.present();

          await modal.onWillDismiss();
        })
      ),
    { dispatch: false }
  );

  async openNoQuestionsModal() {
    const modal = await this.modalCtrl.create({
      component: NoQuestionnairePage,
    });
    modal.present();
  }
  constructor(
    private store: Store,
    private actions$: Actions,
    private modalCtrl: ModalController,

    private questionsApiService: QuestionsApiService,
    private questionsService: QuestionsService,
    private timeService: TimerService
  ) {}
}
