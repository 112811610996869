import { routerActionTypes } from '@actions/router.actions';
import { settingsActionTypes } from '@actions/settings.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';
import { setEnergyCountdownStart } from '@actions/timer.actions';
import { TimeDuration } from '@models/timer.model';
import { UserActionTypes } from '@actions/user.actions';
import { userEnergyPointsSelector } from '../selectors/user.selectors';
import { energyPointsCapSelector$ } from '../selectors/params.selector';
import { progressActionTypes } from '@actions/progress.actions';
import { IProgressExchange } from '@models/progress.model';
import { TimerService } from '../../services/timer.service';

@Injectable()
export class TimerEffects {
  // setFreeEnergyCountdownEndEffect = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(timerActionTypes.SET_FREE_ENERGY_COUNTDOWN_START),
  //     withLatestFrom(this.store.select(energyCountdownStartSelector$)),
  //     mergeMap((result: any) => {
  //       const startDate: Date = result[0].countdownStart;
  //       let endDate: Date = new Date(
  //         startDate.getTime() +
  //           new TimeDuration(environment.energyCooldown).getTime()
  //       );

  //       return of(
  //         setFreeEnergyCountdownEnd({
  //           countdownEnd: endDate,
  //         })
  //       );
  //     })
  //   );
  // });
  // updateFreeEnergyCountdownStringEffect = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(timerActionTypes.UPDATE_FREE_ENERGY_COUNTDOWN_STRING),
  //     withLatestFrom(this.store.select(freeEnergyCountdownEndSelector$)),
  //     mergeMap((result: any) => {
  //       const endDate: Date = result[0].countdownStart;
  //       let endDate: Date = new Date(startDate);
  //       this.timer.addMinutes(endDate, environment.energyPointsCountdownPeriod);
  //       return of(
  //         setFreeEnergyCountdownEnd({
  //           countdownEnd: endDate,
  //         })
  //       );
  //     })
  //   );
  // });

  resetEnergyCountdown = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.DECREASE_ENERGY_POINTS),
      withLatestFrom(this.store.select(userEnergyPointsSelector)),
      withLatestFrom(this.store.select(energyPointsCapSelector$)),
      mergeMap(([[_, latestEnergyPoints], energyCap]) => {
        const previousEnergyIsCapped = latestEnergyPoints === energyCap - 1;
        // Only and only then we should start the timer (energy cap drop to energy cap -1 )
        if (previousEnergyIsCapped) {
          return of(
            setEnergyCountdownStart({
              countdownStart: new Date(),
            })
          );
        }
        return of();
      })
    );
  });

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private store: Store,
    private timer: TimerService
  ) {}
}
