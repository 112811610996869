import { redirectToHomePageAction } from '@actions/router.actions';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.page.html',
  styleUrls: ['./confirm.page.scss'],
})
export class ConfirmPage implements OnInit {
  constructor(private modalCtrl: ModalController, private store: Store) {}
  confirmationMessage = 'Are you sure?';
  confirmOption = 'Yes';
  cancelOption = 'No';
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }
  ngOnInit() {}
}
