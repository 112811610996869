import { progressActionTypes } from '@actions/progress.actions';
import { questActionTypes, setQuestsAction } from '@actions/quest.actions';
import { Injectable } from '@angular/core';
import { IQuestsProgress } from '@models/progress.model';
import { ITemperedQuest } from '@models/quest.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs';
import { QuestService } from 'src/app/services/quest.service';
import { questsProgressSelector$ } from '../selectors/progress.selector';
import { questsListSelector$ } from '../selectors/quest.selectors';

@Injectable()
export class QuestEffects {
  // Used on app init (app.component.ts)
  loadQuestsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(questActionTypes.LOAD_QUESTS),
      exhaustMap((data: any) =>
        // Loads from json file

        this.questService.getQuests().pipe(
          map((quests) => {
            const temperedQuests: ITemperedQuest[] = quests.map((quest) => {
              return { ...quest, timesPlayed: 0, unlocked: false };
            });
            return setQuestsAction({ quests: temperedQuests });
          }),
          catchError(() => {
            throw new Error(`Error in quests loading...`);
          })
        )
      )
    )
  );

  updateQuestsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressActionTypes.SET_QUEST_PROGRESS),
      withLatestFrom(this.store.select(questsListSelector$)),
      withLatestFrom(this.store.select(questsProgressSelector$)),
      map((value: any) => {
        const questsList: ITemperedQuest[] = value[0][1];
        const questsProgress: IQuestsProgress = value[1];

        const updatedQuests = this.questService.updateQuestsProgress(
          questsList,
          questsProgress
        );
        return setQuestsAction({ quests: updatedQuests });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private questService: QuestService,
    private store: Store
  ) {}
}
