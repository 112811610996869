import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-svg-loader',
  templateUrl: './svg-loader.component.html',
  styleUrls: ['./svg-loader.component.scss'],
})
export class SvgLoaderComponent implements OnInit, OnChanges {
  @Input() svgPath: string = '';
  @Input() color: string = '';
  @Input() size: number = 50;
  @ViewChild('svgContainer', { static: true }) svgContainer!: ElementRef;

  constructor(private httpClient: HttpClient) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.loadSVG();
  }

  ngOnInit() {
    this.loadSVG();
  }
  private async loadSVG(): Promise<void> {
    if (this.svgPath) {
      try {
        const svgContent = (await firstValueFrom(
          this.httpClient.get(this.svgPath, { responseType: 'text' })
        )) as string;
        this.svgContainer.nativeElement.innerHTML = this.applyColor(
          svgContent,
          this.color,
          this.size
        );
      } catch (error) {
        console.error('Error loading SVG:', error);
      }
    }
  }
  private changeSize(svgContent: string, size: number): string {
    if (size) {
      const regex = new RegExp('(width=")[^"]+(height=")[^"]+', 'g');
      const svgStartTagMatch = svgContent.match(/<svg[^>]+>/);
      if (svgStartTagMatch) {
        const svgStartTag = svgStartTagMatch[0];
        const viewBoxMatch = svgStartTag.match(/viewBox="([^"]+)"/);
        const viewBox = viewBoxMatch ? viewBoxMatch[1] : '0 0 50 50';
        const newSvgTag = `<svg width="${size}" height="${size}" viewBox="${viewBox}">`;
        return svgContent.replace(/<svg[^>]+>/, newSvgTag);
      } else {
        console.warn('No <svg> start tag found.');
      }
    }
    return svgContent;
  }
  private changeFill(svgContent: string, color: string): string {
    const regex = new RegExp('(fill:)lime', 'g');
    return svgContent.replace(regex, `$1${color}`);
  }
  private changeStroke(svgContent: string, color: string): string {
    const regex = new RegExp('(stroke:)lime', 'g');
    return svgContent.replace(regex, `$1${color}`);
  }

  private applyColor(svgContent: string, color: string, size: number): string {
    return this.changeSize(
      this.changeStroke(this.changeFill(svgContent, color), color),
      size
    );
  }
}
