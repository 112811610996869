import { Injectable } from '@angular/core';
import { IQuest, ITemperedQuest } from '@models/quest.model';
import { HttpClient } from '@angular/common/http';
import {
  filter,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { redirectToQuestPageAction } from '@actions/router.actions';
import { setActiveQuestAction } from '@actions/quest.actions';
import { IQuestsProgress } from '@models/progress.model';
import { questsListSelector$ } from '../store/selectors/quest.selectors';
import { waitForAsync } from '@angular/core/testing';
@Injectable({
  providedIn: 'root',
})
export class QuestService {
  private questsUrl = '../../assets/data/quests.json';

  constructor(private http: HttpClient, private store: Store) {}
  getQuests(): Observable<IQuest[]> {
    return this.http.get<IQuest[]>(this.questsUrl);
  }

  getQuest(questId: number): Observable<IQuest | undefined> {
    return this.http
      .get<IQuest[]>(this.questsUrl)
      .pipe(map((quests) => quests.find((quest) => quest.id == questId)));
  }

  // async getActiveQuestDetails(): Promise<IQuest | undefined> {
  //   const activeQuest$ = this.store.select(activeQuestSelector);
  //   const activeQuest = await firstValueFrom(activeQuest$);
  //   return this.quests.quests.find(
  //     (quest: IQuest) => quest.id === activeQuest.id
  //   );
  // }

  goToQuest(id: number): void {
    this.store.dispatch(setActiveQuestAction({ questId: id }));
    this.store.dispatch(redirectToQuestPageAction());
  }

  updateQuestsProgress(quests: IQuest[], questsProgress: IQuestsProgress) {
    const updateQuests = quests.map((quest: IQuest): ITemperedQuest => {
      if (questsProgress[quest.id]) {
        return { ...quest, ...questsProgress[quest.id] };
      } else {
        return { ...quest, ...{ unlocked: false, timesPlayed: 0 } };
      }
    });
    return updateQuests;
  }

  async findNextquestId(currentID: number): Promise<number> {
    const questsList = await firstValueFrom(
      this.store.select(questsListSelector$)
    );
    const sortedQuestsList = [...questsList].sort((a, b) =>
      a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
    );
    const currentIndex = sortedQuestsList.map((x) => x.id).indexOf(currentID);
    try {
      return sortedQuestsList[currentIndex + 1].id;
    } catch {
      return 0;
    }
  }
}
