import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IQuestionnaireAPI } from '../models/question.model';
import { ApiResponse } from './dtos/response.dto';
// import { dependenciesArray, questionsArray } from '../pages/questions/qdata';

@Injectable({ providedIn: 'root' })
export class QuestionsApiService {
  private API_URL = environment.API_URL;

  private url = `${this.API_URL}/template/questionnaire/all`;
  constructor(private http: HttpClient) {}

  public getAPIQuestionnaires(): Observable<ApiResponse<IQuestionnaireAPI[]>> {
    return this.http.get(this.url) as Observable<
      ApiResponse<IQuestionnaireAPI[]>
    >;
  }
}
