import { IGlobalParams } from '@models/params.model';
import { createAction, props } from '@ngrx/store';

export enum paramsActionTypes {
  LOAD_PARAMS = '[PARAMS] LOAD',
  LOAD_PARAMS_SUCCESS = '[PARAMS] LOAD SUCCESS',
  SET_PARAMS = '[PARAMS] SET',
}

export const setParamsAction = createAction(
  paramsActionTypes.SET_PARAMS,
  props<{ params: IGlobalParams }>()
);
export const loadParamsAction = createAction(paramsActionTypes.LOAD_PARAMS);
export const setParamsActionSuccess = createAction(
  paramsActionTypes.LOAD_PARAMS_SUCCESS
);
