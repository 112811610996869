export const SOUNDS: IAppSounds = {
  background: 'bg.mp3',
  buttonClick: 'btn.mp3',
  quest: 'quest.mp3',
} as const;

interface IAppSounds {
    background: string;
    buttonClick: string;
    quest:string;
}
export type SoundType = keyof IAppSounds;