import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  authPrefix,
  gamePrefix,
  registerPrefix,
  routePaths,
} from './app-routing/routes';
import { AuthGuardService } from './@core/guards/auth.guard';
const routes: Routes = [
  {
    path: authPrefix,
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
  },

  {
    path: gamePrefix,
    // component: GamePage,
    loadChildren: () =>
      import('./pages/game/game.module').then((m) => m.GamePageModule),
    canActivate: [AuthGuardService],
  },

  {
    path: '',
    redirectTo: routePaths.auth.login,
    pathMatch: 'full',
  },
  {
    path: 'splash',
    // component: SplashPage,
    loadChildren: () =>
      import('./pages/splash/splash.module').then((m) => m.SplashPageModule),
  },
  {
    path: 'welcome',
    // component: WelcomePage,
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'game-description',
    // component: GameDescriptionPage,
    loadChildren: () =>
      import('./pages/game-description/game-description.module').then(
        (m) => m.GameDescriptionPageModule
      ),
  },
  {
    path: 'confirm',
    // component: ConfirmPage,
    loadChildren: () =>
      import('./pages/confirm/confirm.module').then((m) => m.ConfirmPageModule),
  },
  {
    path: 'answers-list',
    // component: AnswersListPage,
    loadChildren: () =>
      import('./pages/answers-list/answers-list.module').then(
        (m) => m.AnswersListPageModule
      ),
  },  {
    path: 'reset-pw',
    loadChildren: () => import('./pages/reset-pw/reset-pw.module').then( m => m.ResetPwPageModule)
  },
  {
    path: 'parental-gate',
    loadChildren: () => import('./pages/parental-gate/parental-gate.module').then( m => m.ParentalGatePageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
