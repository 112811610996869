import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  showToast(message: string, toastClass: string, duration: number) {
    this.toastController
      .create({
        // header: 'Login failed',
        message: message,
        position: 'bottom',
        duration: duration,
        cssClass: toastClass,
        // buttons: [
        //   {
        //     side: 'end',
        //     text: 'Dismiss',
        //     role: 'dismiss',
        //     handler: () => {
        //       console.log('Dismiss clicked');
        //     },
        //   },
        // ],
      })
      .then((obj) => {
        obj.present();
      });
  }
  constructor(public toastController: ToastController) {}
}

// https://www.freakyjolly.com/ionic-4-adding-toasts-in-ionic-4-application-using-ui-components-with-plugins/
