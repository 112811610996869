import { Component, OnInit } from '@angular/core';
import { INTRO_IDS } from 'src/app/services/intro/data/intro_ids.intro';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent implements OnInit {
  constructor() {}
  SettingsIntroIds = INTRO_IDS.SETTINGS.ids;

  ngOnInit() {}
}
